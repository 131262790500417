import { CHRISTMAS_ALA_CARTE_MENU, CNY_ALA_CARTE_MENU } from '@/assets/js/highlight-menu-helper'
import { MENU_IDS } from '@/assets/js/order-helper'

function getInitialState() {
  return {
    menuId: null,
    menuCover: null,
    selectedMenu: null,
    selectedDate: null,
    selectedPax: null,
    selectedAbout: [],
    seminarMenus: [],
    highlightMenus: [],
    addOnMenus: [],
    iframeMenu: null
  }
}

export const menu = {
  state: getInitialState(),

  mutations: {
    resetMenuState(state) {
      const initialState = getInitialState()
      for (var key in state) {
        state[key] = initialState[key]
      }
    },
    setMenuId(state, value) {
      state.menuId = value
    },
    setMenuCover(state, value) {
      state.menuCover = value
    },
    setSelectedMenu(state, value) {
      state.selectedMenu = value
    },
    setSelectedDate(state, value) {
      state.selectedDate = value
    },
    setSelectedPax(state, value) {
      state.selectedPax = value
    },
    setSelectedAbout(state, value) {
      state.selectedAbout = value
    },
    setSeminarMenus(state, value) {
      state.seminarMenus = value
    },
    setHighlightMenus(state, value) {
      state.highlightMenus = value
    },
    setAddOnMenus(state, value) {
      state.addOnMenus = value
    },
    setIframeMenu(state, value) {
      state.iframeMenu = value
    }
  },

  actions: {
    resetMenuState({ commit }) {
      commit('resetMenuState')
    },
    setMenuId({ commit }, value) {
      commit('setMenuId', value)
    },
    setMenuCover({ commit }, value) {
      commit('setMenuCover', value)
    },
    setSelectedMenu({ commit }, value) {
      commit('setSelectedMenu', value)
    },
    setSelectedDate({ commit }, value) {
      commit('setSelectedDate', value)
    },
    setSelectedPax({ commit }, value) {
      commit('setSelectedPax', value)
    },
    setSelectedAbout({ commit }, value) {
      commit('setSelectedAbout', value)
    },
    setSeminarMenus({ commit }, value) {
      commit('setSeminarMenus', value)
    },
    setHighlightMenus({ commit }, value) {
      commit('setHighlightMenus', value)
    },
    setAddOnMenus({ commit }, value) {
      commit('setAddOnMenus', value)
    },
    setIframeMenu({ commit }, value) {
      commit('setIframeMenu', value)
    }
  },

  getters: {
    getMenuId: state => state.menuId,
    getMenuCover: state => state.menuCover,
    getSelectedMenu: state => state.selectedMenu,
    getSelectedDate: state => state.selectedDate,
    getSelectedPax: state => state.selectedPax,
    getSelectedAbout: state => state.selectedAbout,
    getSeminarMenus: state => state.seminarMenus,
    getHighlightMenus: state => state.highlightMenus,
    getAddOnMenus: state => state.addOnMenus,
    isCnyAlaCarteMenu: state => state.menuId === CNY_ALA_CARTE_MENU.id,
    isChristmasAlaCarteMenu: state => state.menuId === CHRISTMAS_ALA_CARTE_MENU.id,
    isFestiveAlaCarteMenu: (state, getters) => {
      return getters.isCnyAlaCarteMenu || getters.isChristmasAlaCarteMenu || [MENU_IDS.cnyAlaCarteAddOns, MENU_IDS.christmasAlaCarteAddOns].includes(parseInt(state.menuId))
    },
    getIframeMenu: state => state.iframeMenu,
    getMenuDisabledDates: state => {
      if (state.selectedMenu && state.selectedMenu.menuBlocks) {
        return state.selectedMenu.menuBlocks.map((menuBlock) => {
          const startDate = menuBlock.startDate ? new Date(menuBlock.startDate) : new Date()
          const endDate = menuBlock.endDate ? new Date(menuBlock.endDate) : new Date('31 Dec 2100')
          return [startDate, endDate]
        })
      }
      return []
    }
  }
}
