import SpineApi, { SPINE_API_URL } from '@/api/base/SpineApi'

export default {
  login(user) {
    const data = {
      web_login: true,
      user
    }
    return SpineApi.post(`${SPINE_API_URL}/login`, data)
  },
  fetchSessionUserId() {
    return SpineApi.get(`${SPINE_API_URL}/session_user_id`)
  },
  logout() {
    return SpineApi.delete(`${SPINE_API_URL}/logout`)
  },
  fetchFeatures() {
    return SpineApi.get(`${SPINE_API_URL}/features`)
  },
  fetchProfile() {
    return SpineApi.get(`${SPINE_API_URL}/account/profile`)
  },
  checkEmailAvailable(email) {
    const data = {
      email
    }
    return SpineApi.post(`${SPINE_API_URL}/email_available`, data)
  }
}
