import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faMediumM } from '@fortawesome/free-brands-svg-icons/faMediumM'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faMediumM, faFacebookF, faInstagram
)

Vue.component('fa', FontAwesomeIcon)
