import Vue from 'vue'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

// use CSS position values instead of CSS transform
// https://github.com/Akryum/v-tooltip/issues/233
VTooltip.options.defaultPopperOptions = {
  modifiers: {
    computeStyle: {
      gpuAcceleration: false
    }
  }
}
