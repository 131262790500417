import Vue from 'vue'

const mixins = {
  methods: {
    trackProductClicked(id, name, type) {
      window.analytics.track('Product Clicked', {
        product_id: id,
        name: name,
        type: type
      })
    },
    trackProductViewed(id, name, type) {
      window.analytics.track('Product Viewed', {
        product_id: id,
        name: name,
        type: type
      })
    }
  }
}

Vue.mixin(mixins)
