import Vue from 'vue'
import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Singapore')

Vue.use(require('vue-moment'))

Object.defineProperties(Vue.prototype, {
  $momenttz: {
    get: function get() {
      return moment
    }
  }
})

Vue.momenttz = moment
