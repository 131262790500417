import { camelizeKeys, decamelizeKeys } from 'humps'
import first from 'lodash/first'
import mapValues from 'lodash/mapValues'
import size from 'lodash/size'

const MAXIMUM_RETRIES = 3
const BASE_HEADER = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

const retryPromise = (operation, retries, shouldRetry) => {
  if (retries <= 1) return operation()
  return operation().catch((reason) => {
    if (shouldRetry(reason)) { return retryPromise(operation, retries - 1, shouldRetry) }
    return Promise.reject(reason)
  })
}

export default function HTTPService() {
  return {
    _getMaximumRetries() { return MAXIMUM_RETRIES },
    _getBaseHeader() { return BASE_HEADER },
    _getPlatform() { },
    _getClient() { },
    _onUnauthorised() { },
    _clearOldSession() { },
    _getAccessToken() { },
    _getAuthorizationKey() { },
    _getSessionUuid() { },

    _forceLogout(response) {
      this._onUnauthorised()
      return response.json().then((responseJSON) => {
        const camelCaseJSON = camelizeKeys(responseJSON)

        // Clear session uuid if user has logged out elsewhere
        if (camelCaseJSON.code === 401003) this._clearOldSession()

        return Promise.reject(camelCaseJSON)
      })
    },

    _getRequestHeaders() {
      const accessToken = this._getAccessToken()
      const authorizationKey = this._getAuthorizationKey()
      const sessionUuid = this._getSessionUuid()

      return {
        ...this._getBaseHeader(),
        ...(authorizationKey ? { Authorization: authorizationKey } : {}),
        ...(accessToken ? { Authorization: `Token token=${accessToken}` } : {}),
        'X-Platform': this._getPlatform(),
        'X-Client': this._getClient(),
        'sessionUuid': sessionUuid
      }
    },

    _fetch(...args) {
      return fetch(...args)
    },

    _fetchRetry(...args) {
      return retryPromise(
        () => this._fetch(...args),
        this._getMaximumRetries(),
        (reason) => reason.message === 'Network request failed'
      )
    },

    _request(url, method, body) {
      const fetchParams = {
        method,
        headers: this._getRequestHeaders(),
        credentials: 'omit'
      }

      if (body) {
        const snakeCaseJSON = decamelizeKeys(body)
        fetchParams.body = JSON.stringify(snakeCaseJSON)
      }

      return this._fetchRetry(url, fetchParams)
        .then((response) => {
          if (response.status === 401) return this._forceLogout(response)
          return response
            .json()
            .catch((error) => {
              const errorMetadata = {
                url,
                method,
                status: response.status,
                request: JSON.stringify(body),
                response: JSON.stringify(response)
              }
              // eslint-disable-next-line no-console
              console.log(error, errorMetadata)
              /**
               *  TODO: Log error to sentry with error and errorMetadata
               *  TODO: Move error message to en.json
               */
              return Promise.reject(new Error('Error Message'))
            })
            .then((responseJSON) => {
              const camelCaseJSON = camelizeKeys(responseJSON)
              if (response.status >= 200 && response.status < 400) {
                return {
                  _status: response.status,
                  ...camelCaseJSON
                }
              }
              return Promise.reject(camelCaseJSON)
            })
        })
        .catch((reason) => {
          if (reason.errors) { return Promise.reject(mapValues(reason.errors, first(reason.errors))) }
          if (reason.error) { return Promise.reject(reason.error) }
          return Promise.reject(reason)
        })
    },

    setup({
      getMaximumRetries,
      getBaseHeader,
      getPlatform,
      getClient,
      onUnauthorised,
      clearOldSession,
      getAccessToken,
      getAuthorizationKey,
      getSessionUuid
    }) {
      if (getMaximumRetries) this._getMaximumRetries = getMaximumRetries
      if (getBaseHeader) this._getBaseHeader = getBaseHeader
      if (getPlatform) this._getPlatform = getPlatform
      if (getClient) this._getClient = getClient
      if (onUnauthorised) this._onUnauthorised = onUnauthorised
      if (clearOldSession) this._clearOldSession = clearOldSession
      if (getAccessToken) this._getAccessToken = getAccessToken
      if (getAuthorizationKey) this._getAuthorizationKey = getAuthorizationKey
      if (getSessionUuid) this._getSessionUuid = getSessionUuid
    },

    post(url, params) {
      return this._request(url, 'POST', params)
    },

    patch(url, params) {
      return this._request(url, 'PATCH', params)
    },

    delete(url, params) {
      return this._request(url, 'DELETE', params)
    },

    _getQueryString(queryParams) {
      if (size(queryParams) === 0) return ''
      const decamelized = decamelizeKeys(queryParams)
      return `?${Object.keys(decamelized).map((key) => `${key}=${decamelized[key]}`).join('&')}`
    },

    get(url, queryParams) {
      const queryString = this._getQueryString(queryParams)
      return this._request(url + queryString, 'GET')
    }
  }
}
