import UserApi from '@/api/UserApi'
import * as Sentry from '@sentry/browser'

export const user = {
  namespaced: true,
  state: {
    authToken: {},
    sessionUserId: null,
    userProfile: {},
    userId: null,
    features: {}
  },

  mutations: {
    setAuthToken(state, value) {
      state.authToken = value
    },
    setSessionUserId(state, value) {
      state.sessionUserId = value
    },
    setUserProfile(state, value) {
      state.userProfile = value
    },
    setUserId(state, value) {
      state.userId = value
      if (state.userId) {
        Sentry.configureScope(scope => {
          scope.setUser({ id: state.userId })
        })
        window.analytics.identify(state.userId)
      }
    },
    setFeatures(state, value) {
      state.features = value
    }
  },

  actions: {
    async login({ commit }, payload) {
      try {
        const result = (await UserApi.login(payload.user))
        commit('setAuthToken', result.accessToken.token)
        commit('setUserId', result.user.userId)
      } catch(e) {
        console.log('login failed', e)
        // Sentry
      }
    },
    setUserData({ commit }, payload) {
      commit('setAuthToken', payload.accessToken.token)
      commit('setUserProfile', payload.profile)
    },
    async fetchSessionUserId({ commit }) {
      try {
        const result = (await UserApi.fetchSessionUserId())
        commit('setSessionUserId', result)
      } catch(e) {
        console.log('fetchSessionUserId failed', e)
        // Sentry
      }
    },
    setFeatures({ commit }, value) {
      commit('setFeatures', value)
    }
  },

  getters: {
    authToken: state => state.authToken,
    userId: state => state.userProfile.id,
    sessionUserId: state => state.userProfile.id || state.sessionUserId,
    isAuthenticated: state => !!state.userProfile.id,
    features: state => state.features
  }
}
