import HTTPService from '@/api/base/HTTPService'
import cookies from '@/assets/js/cookies'
const uuid = require('uuid/v4')

const SPINE_API_VERSION_ROOT = '/api/v4'
export const SPINE_BASE_API_URL = process.env.VUE_APP_SPINE_URL
export const SPINE_API_URL = `${SPINE_BASE_API_URL}${SPINE_API_VERSION_ROOT}`

const PLATFORM = 'catering'

const SpineApi = HTTPService()
SpineApi.setup({
  getPlatform() {
    return PLATFORM
  },
  getAccessToken: () => {
    return cookies.getCookie('external_access_token')
  },
  onUnauthorised: () => {
    return cookies.deleteCookie('external_access_token')
  },
  clearOldSession: () => {
    return cookies.deleteCookie('session_user_uuid')
  },
  getSessionUuid: () => {
    let sessionUserUuid = cookies.getCookie('session_user_uuid')
    if (!sessionUserUuid) {
      sessionUserUuid = uuid()
      cookies.setCookie('session_user_uuid', sessionUserUuid)
    }
    return sessionUserUuid
  }
})
export default SpineApi
