/* eslint-disable no-template-curly-in-string */

export default {
  home: {
    bannerHeader: 'You have good taste',
    bannerText:
      "And we have great food. For every guest and any occasion, there’s always room for Grain.<br><span class='byline-question'>Looking for a personalised touch? </span>",
    bannerCta: "Let's chat.",
    festiveSurchargeNotice:
      'From 29 Jan - 8 Feb and 14 Feb - 24 Feb, a festive surcharge of $1 ($1.09 w/GST) per guest applies to all non-CNY menus.',
    profileHeader: '"The best thing since sliced bread"',
    onlineRestaurantHeader: 'Online restaurant',
    onlineRestaurantText: 'Everyday meals. Delivered to your doorstep.',
    cateringHeader: 'Catering',
    cateringText: 'Catered food. Designed to impress.',
    productActionTitle: 'Browse menu',
    productActionArrow: '❯'
  },
  about: {
    bannerHeader: 'What will we eat next?',
    bannerText:
      'We are on a mission to create food experiences that will put an end to this dilemma once and for all. By pushing the limits of how food makes us feel, we can build a community that has only one thing on their mind: "What will we <span class="NoWrap">eat next</span>?"',
    beliefHeader: "We believe there's no limit to food.",
    keyPoint1:
      'Our happy place is the kitchen, where we <span class="{displayClass}">experiment</span>, <span class="{displayClass}">play</span> and <span class="{displayClass}">cook up</span> dishes that not only sustain, but also satisfy.',
    keyPoint2:
      'Our dishes are inspired by techniques, cuisines and flavours from around the world. A Grain meal is <span class="{displayClass}">familiar</span> yet <span class="{displayClass}">unexpected</span>.',
    keyPoint3:
      'We take pride in giving the kind of <span class="{displayClass}">customer service</span> that we would like to receive ourselves.',
    featuredHeader: 'As featured in',
    philosophyHeader: 'Our core philosophy is people come first',
    philosophyText:
      'Grain is a unique group of people who share a magical vision of the future. This way, we create a more fun, meaningful, lasting and successful organisation. We believe that <span class="{displayClass}">happy teams</span> make <span class="{displayClass}">happy customers</span>.',
    investorsHeader: 'Our investors',
    investorsText:
      'Like us, our investors believe in high standards and delighting customers. With their support, we are able to focus on providing the best food experience for everyone.',
    investorsIndependent:
      'Our roster of investors also include K2 Global, FoodXervices, Zopim mafia, Koh Boon Hwee, Wee Teng Wen, Ivan Lee, and Lim Ho Kee.'
  },
  navbar: {
    rte: 'Meals On Demand',
    catering: 'Catering',
    mealPlans: 'Meal Plan',
    rteContact: '+65 3163 5335',
    rteContactLink: 'tel:+6531635335',
    cateringContact: '+65 3163 5336',
    cateringContactLink: 'tel:+6531635336',
    contactUs: 'Contact us',
    contactUsLink: '/contact',
    login: 'Log in',
    account: 'Account',
    logout: 'Log out'
  },
  footer: {
    company: {
      header: 'Company',
      about: 'About us',
      people: 'Our people',
      stories: 'Our stories',
      deliveryArea: 'Delivery area',
      jobs: 'We’re hiring'
    },
    products: {
      header: 'Products',
      meals: 'Meals On Demand',
      mealPlans: 'Meal Plan',
      catering: 'Catering',
      gallery: 'Gallery',
      giftCards: 'Gift Cards',
      grainSavers: 'Grain Savers'
    },
    help: {
      header: 'Help',
      faq: 'FAQ',
      contact: 'Contact us'
    },
    mini: {
      terms: 'Terms',
      privacyPolicy: 'Privacy Policy',
      downloadIOS: 'Download iOS app',
      downloadAndroid: 'Download Android app'
    }
  },
  catering: {
    menusLoading: 'Loading menus magically...',
    filteredMenuHeader: 'Recommended menus based on your details',
    filteredOutMenuHeader: 'Other menus',
    customersSectionTitle: 'Trust our customers who love Grain',
    enquirySectionTitle: 'Contact us',
    enquirySectionByline:
      'We love hearing from our customers. Talk to us about anything. We are here to listen and help.',
    afterEnquiryForm:
      'Need help?<br/>Reach us at {phoneLink} or {emailLink}.<br/>Our hotlines are open <span style="white-space:nowrap">Mondays to Saturdays.</span><br/>We try to reply quickly, usually within the <span style="white-space:nowrap">working day.</span>'
  },
  menu: {
    progressTracker: {
      selectMenuLabel: 'Select menu',
      selectItemsLabel: 'Select items',
      checkoutLabel: 'Check out'
    },
    deleteOrder: {
      confirmationTitle: 'Are you sure you want to delete your order?',
      confirmationMessage:
        'You are about to delete <strong>Order {orderId}</strong>. This will clear all your details.',
      confirmLabel: 'Delete order',
      cancelLabel: 'Cancel'
    }
  },
  checkout: {
    paymentLabel: 'Payment',
    payWithCardLabel: 'Pay with credit or debit card',
    payWithOtherMethodLabel: 'Pay with other method (Bank transfer/Cheque/Invoice)',
    placeOrderButtonTitle: 'Confirm and place order',
    unexpectedError: 'An unexpected error occurred'
  },
  success: {
    title: "One last step from confirming <span class='NoWrap'>your order</span>",
    byline:
      "Thanks for choosing the Grain experience. We will be contacting you within 1-2 working days to <span class='NoWrap'>confirm your order</span>.<br><br>If you need any help, reach us at {phoneLink} or {emailLink}.",
    paidTitle: 'Thanks for your order',
    paidByline:
      'An order confirmation has been sent to your inbox.<br>If you need any help, reach us at {phoneLink} or {emailLink}.',
    backToHomeButtonTitle: 'Back to Grain',
    viewQuotationTitle: 'View order quotation',
    viewConfirmationTitle: 'View order confirmation'
  },
  announcement: {
    title: 'Open on Sundays again',
    body:
      'We heard your requests to serve on Sundays again. So here we are. We are opening on Sundays — if there are huge events. This way we can help more people eat better, while keeping some Sundays rest days for everyone at Grain.'
  },

  weddingBanner: {
    stylisedTitle: 'For unforgettable memories',
    title: {
      weddings: 'Weddings',
      by: 'by',
      grain: 'Grain'
    },
    byline:
      'Celebrate your wedding day with the bliss of delicious food surrounded by your loved ones.',
    actionLabel: 'Plan now'
  },

  galleryBanner: {
    buttonTitle: 'View gallery'
  },

  common: {
    loading: 'Loading...',
    price: '${price}',
    usualPrice: 'Usual price ${price}',
    perPax: '/guest',
    perItem: '/item',
    priceWithGst: '(${priceWithGst} w/ GST)',
    pricePerPax: '${price}/guest',
    deliveryFeeWaivedLabel: 'Waived',
    additionalCharge: '+${additionalCharge}',
    betaTag: 'Beta',
    newTag: 'New'
  },

  commonModal: {
    defaultConfirmLabel: 'OK',
    defaultCancelLabel: 'Cancel'
  },

  addAddressModal: {
    title: 'Add new address',
    addressLabel: 'Address',
    floorLabel: 'Floor',
    unitNoLabel: 'Unit number',
    blockTowerLabel: 'Block/tower (optional)',
    companyNameLabel: 'Company name (optional)',
    addAddressButtonTitle: 'Add'
  },

  addressSelectorModal: {
    defaultTitle: 'Select delivery address',
    addAddress: 'Add a new address'
  },

  availableTimeSlotsModal: {
    title: 'Select a time'
  },

  enquiryForm: {
    contactPersonLabel: 'Name',
    dateLabel: 'Event date (optional)',
    emailLabel: 'Email',
    phoneLabel: 'Mobile number',
    headcountLabel: 'Number of guests',
    companyNameLabel: 'Company name (optional)',
    requirementsLabel: 'Event details (optional)',
    requirementsPlaceholder:
      'Let us know details such as your venue address, event time, preferred menu, dietary requirements, etc. so we can plan the perfect food experience for you.',
    promoCodeLabel: 'Promo code (optional)',
    submitLabel: 'Submit',
    successTitle: 'Thanks',
    successMessage: 'We will be in touch.',
    errorTitle: 'Error'
  },

  eventAboutPicker: {
    placeholder: 'Tell us more'
  },

  eventDatePicker: {
    placeholder: 'Event date',
    emptyError: 'Please select your event date',
    invalidError: 'Selected date is invalid'
  },

  eventFulfilmentOptions: {
    pickup: 'Pick up from 5 Burn Road #05-01',
    delivery: 'Deliver to my address',
    minimumForDelivery: 'Delivery available for orders above $100'
  },

  eventPaxInput: {
    placeholder: 'Number of guests',
    minimumPaxError: 'Minimum {minimumPax} guests',
    multipleOfTwoError: 'We only accept number of guests in multiples of 2',
    multipleOfFiveError: 'We only accept number of guests in multiples of 5',
    emptyError: 'Please indicate the number of guests'
  },

  eventTimePicker: {
    placeholder: 'Event time',
    pickupPlaceholder: 'Pick up time',
    emptyError: 'Please select your event time',
    invalidTimeRangeError: 'Please select time slots from <strong>{earliestTimeRange1}-{latestTimeRange1}</strong> for {serviceTimeRange1} or <strong>{earliestTimeRange2}-{latestTimeRange2}</strong> for {serviceTimeRange2}. Contact us at <a href="tel:+6531635336">+65 3163 5336</a> for catering service outside this time.',
    invalidSeminarTimeError: {
      breakfast: 'Please select time slots from <strong>{earliestTime}-{latestTime}</strong> for breakfast. Contact us at <a href="tel:+6531635336">+65 3163 5336</a> for catering service outside this time.',
      lunch: 'Please select time slots from <strong>{earliestTime}-{latestTime}</strong> for lunch. Contact us at <a href="tel:+6531635336">+65 3163 5336</a> for catering service outside this time.',
      tea: 'Please select time slots from <strong>{earliestTime}-{latestTime}</strong> for tea. Contact us at <a href="tel:+6531635336">+65 3163 5336</a> for catering service outside this time.'
    },
    invalidError: 'Please select between {earliestTime} and {latestTime}',
    unavailableError: '{selectedTime} is unavailable.',
    closestTimeSlots: 'The closest available time slots are',
    closestTimeSlot: 'The closest available time slot is',
    noAvailableTimeSlots: 'There are no more available time slots for {selectedDate}'
  },

  fulfilmentInfo: {
    leadTimeLabel: 'Lead time:',
    leadTime: '<strong>4 weekdays</strong> in advance',
    servingTimeLabel: 'Serving time:',
    servingTime: 'Any time from <strong>{earliestTime}</strong>-<strong>{latestTime}</strong>',
    servingTimeRanges: 'Any time from <strong>{earliestLunchTime}-{latestLunchTime}</strong> or <strong>{earliestDinnerTime}-{latestDinnerTime}</strong>',
    threeServingTimeRanges: 'Any time from <strong>{earliestTimeRange1}-{latestTimeRange1}</strong>, <strong>{earliestTimeRange2}-{latestTimeRange2}</strong>, or <strong>{earliestTimeRange3}-{latestTimeRange3}</strong>',
    setupTimeLabel: 'Setup time:',
    setupTime: '<strong>1 hour</strong> before serving time',
    teardownTimeLabel: 'Teardown time:',
    teardownTime: 'Around <strong>2 hours and 30 minutes</strong> from event time (latest <strong>10:30PM</strong>)'
  },

  addOns: {
    title: 'Add-ons',
    byline: 'Available from {time} onwards',
    servesLabel: 'serves {count} guests',
    minimumOrder: 'Minimum order of {minimum} items.',
    availableStartEndDate: 'Available from {startDate} to {endDate}',
    availableStartDate: 'Available from {startDate}',
    availableEndDate: 'Available till {endDate}',
    availableStartEndTime: 'Available from {startTime} to {endTime}',
    availableStartTime: 'Available from {startTime} onwards',
    availableEndTime: 'Available till {endTime}',
    unavailable: 'This add-on is not available for the selected date',
    minSelectionError: 'You must choose either 0 or {count} item | You must choose either 0 or {count} items',
    maxSelectionError: 'You can only choose {count} item | You can only choose {count} items',
    fillInDateModal: {
      title: 'Event date not selected',
      message: 'Please select your event date'
    }
  },

  highlightAddOns: {
    title: 'Add more prosperity',
    byline: 'Available from Jan 10 – Feb 10, excluding Jan 25–27',
    impossibleSlidersTitle: 'Impossible™ Slider Add-ons'
  },

  highlightMenus: {
    title: 'Party Set',
    byline: 'Curated to delight 4 to 10 guests.<br>Perfect for cosy celebrations in the comfort of your home.',
    standardMenu: {
      title: 'Buffet',
      byline: ''
    },
    healthyMenu: {
      title: 'Healthy Buffet Menu',
      byline: 'Wholesome dishes packed with superfoods to help you stay energised.'
    },
    bentoMenu: {
      title: 'Bentos',
      byline: "Individually packed meals, designed to power the world's best meetings and events."
    },
    miniMenu: {
      title: 'Buffet To Go',
      byline: ''
    },
    christmasMenu: {
      title: 'Christmas',
      byline: ''
    },
    cnyMenu: {
      title: 'Chinese New Year',
      byline: ''
    },
    nationalMenu: {
      title: 'National Day',
      byline: ''
    },
    festiveSurchargeLabel: 'Festive surcharge (${festiveSurcharge}/guest)'
  },

  customMenu: {
    title: 'Introductory Buffet menu (June exclusive)',
    byline: 'Savour the latest at just <strong>$16.50/guest.</strong>',
    section1Header: '',
    section1Body: 'To celebrate our menu refresh, we’ve put together an introductory menu for you to taste our new creations. It’s a buffet at the price of a meal box. Only available this June.',
    buttonTitle: 'Taste it first'
  },

  loginFormModal: {
    title: 'Welcome back.<br>We missed you.',
    emailLabel: 'Email',
    passwordLabel: 'Password',
    forgotPasswordLabel: 'I forgot my password',
    loginButtonTitle: 'Log in',
    loginButtonProcessingTitle: 'Please wait...'
  },

  menuCard: {
    orderButtonTitle: 'Order now',
    downloadLinkTitle: 'Download the menu',
    addOnsOnlyMenu: 'Order Add-Ons only'
  },

  menuPrefaceCard: {
    menuPrefaceCardTitle: "The same flavours you've come to love, minus the hassle.",
    menuPrefaceCardByline:
      'Opt for our Mini Buffet and High Tea To Go take home sets. They come with disposable containers and cutleries. So you get the same signature Grain experience without the hassle of dealing with a full buffet setup.'
  },

  menuFilter: {
    sectionTitle: 'Order the perfect food experience',
    sectionBylinePartOne: 'Just want to enquire or have an event less than three working days away?',
    sectionBylinePartTwo: 'Contact us.',
    searchButtonTitle: 'Recommend me something',
    clearAllButtonTitle: 'Clear all'
  },

  menuLegend: {
    veganLabel: 'Vegan',
    vegetarianLabel: 'Vegetarian',
    chefRecommendationLabel: "Chef's recommendation"
  },

  menuPricingDetails: {
    suffix: {
      set: 'set',
      guest: 'guest'
    },
    minimumPax: 'Min {count} guests',
    fixedPax: 'For {count} guests'
  },

  menuSectionCard: {
    categoryCountError: 'You can only choose {count} {category}s',
    sectionMealsCountError: 'You can only choose {count} items from this section',
    categorySelectOneMessage: 'Choose one {category}',
    categorySelectMessage: 'Choose {count} {category}s',
    sectionMealsSelectMinOneMessage: 'Choose up to 1 item',
    sectionMealsSelectMinMessage: 'Choose up to {count} items',
    sectionMealsSelectOneMessage: 'Choose 1 item',
    sectionMealsSelectMessage: 'Choose {count} items'
  },

  orderInfoForm: {
    loading: 'Loading...',
    pickupInfo:
      'You have selected self-pickup for some of your orders. Please collect them at Grain <span>(5 Burn Road, #05-01, Singapore 369972)</span>.',
    deliveryInformationTitle: 'Delivery information',
    pickupInformationTitle: 'Pick up information',
    loginPrompt: 'Already have an account? Log in',
    loginPromptAction: 'here',
    logoutPrompt: 'Not you?',
    logoutPromptAction: 'Log out',
    customer: {
      nameLabel: 'First name',
      lastNameLabel: 'Last name',
      contactNumberLabel: 'Mobile number',
      emailLabel: 'Email address',
      companyNameLabel: 'Company name',
      passwordLabel: 'Enter password to create account'
    },
    address: {
      searchAddressPlaceholder: 'Enter street name or postal code',
      deliveryAddressLabel: 'Delivery address',
      pickupAddressLabel: 'Pick up address',
      billingAddressLabel: 'Billing address',
      postalCodeLabel: 'Postal code',
      floorLabel: 'Floor',
      unitNoLabel: 'Unit number',
      blockTowerLabel: 'Building name/block (optional)'
    },
    venueOpenAtSetupTime: 'The place will be open during setup time, which is approximately {setupTimes}.',
    venueOpenAtSetupTimeYes: 'Yes',
    venueOpenAtSetupTimeNo: 'No',
    secondaryContactNameLabel: 'Secondary contact person',
    secondaryContactNumberLabel: 'Secondary contact number',
    corporateOrderLabel: 'This is a corporate order',
    disposablesRequiredLabel: '<strong>Should we include disposable cutlery, plates, and serving utensils with your order?</strong>',
    disposablesRequiredYes: 'Yes, please <strong>include</strong> all disposables.',
    disposablesRequiredNo: 'No, please <strong>exclude</strong> all disposables (I want to save the planet).',
    disposablesRequiredNote: {
      header: 'Note:',
      listItem1: 'Disposables are complimentary.',
      listItem2: 'For Classic Buffet orders, metal serving utensils will be provided even if disposables are excluded.'
    },
    billingInformationTitle: 'Billing information',
    billingAddressSameAsDeliveryAddressLabel: 'Billing address is the same as delivery address',
    billingAddressSameAsPickupAddressLabel: 'Billing address is the same as pick up address',
    billingNameLabel: 'Name',
    billingLastNameLabel: 'Last name',
    createAccountLabel: 'Save my information for next time',
    specialInstructionsLabel: 'Special instructions',
    specialInstructionsPlaceholder:
      'Let us know details such as where to park, how to access your place, etc. so we can make sure we make your event a success.',
    promoCodeLabel: 'Promo code (optional)',
    contactNumberInvalidError: 'Contact number must be 8 numbers, and begin with 8 or 9',
    emailFormatError: 'Invalid email format',
    emailUnavailableError: 'Email already exists',
    specialLocationDeliveryChargeWarning: 'A special location delivery charge may be applied to this location',
    specialLocationDeliveryChargeError: 'Sorry, we do not currently deliver to this location',
    passwordMissingError: 'Password is required',
    passwordLengthError: 'Password must be at least 6 characters'
  },

  order: {
    orderLabel: 'Order {id}',
    orderInvalidError: 'Some details are not right',
    editOrderLabel: 'Edit order',
    fillUpEventDetailsLabel: 'Fill up event details',
    eventSummary: 'Your event is at <strong>{time}</strong>, <strong>{date}</strong>.',
    eventSummaryTime: 'Your event is at <strong>{time}</strong>.',
    eventSummaryDate: 'Your event is on <strong>{date}</strong>.',
    seminarEventSummary: 'You have selected <strong>{menu}</strong> at {time}, <strong>{date}</strong>.',
    seminarEventSummaryTime: 'You have selected <strong>{menu}</strong> at {time}.',
    seminarEventSummaryDate: 'You have selected <strong>{menu}</strong> on <strong>{date}</strong>.',
    seminarEventSummaryMenu: 'You have selected <strong>{menu}</strong>.',
    emptyEventSummary: 'Tell us more about your event to proceed.',
    subtotalLabel: 'Subtotal',
    totalLabel: 'Total',
    totalWithGstLabel: 'Total (w/ GST)',
    additionalChargesTitle: 'Additional charges',
    additionalChargePerPax: '+${price}/guest',
    festiveSurchargeLabel: 'Festive surcharge',
    minimumPaxSurchargeLabel: 'Min guest surcharge',
    addOnsLabel: 'Add-ons',
    deliveryFeeLabel: 'Delivery fee',
    specialLocationDeliveryChargeLabel: 'Special location delivery charge',
    selectedPricePerPax: '${price}/guest (${priceWithGst} w/ GST)',
    selectedPax: '{count} guests',
    servingTimeLabel: 'Serving time:',
    teardownTimeLabel: 'Teardown time:',
    pickupTimeLabel: 'Pick up time:',
    specialInstructionsLabel: 'Special instructions',
    needStairsLabel: '<b>Will we need to access stairs?</b><br/>Please select “Yes” for 10 steps or more. Everything goes to our delivery team heroes.  Any “surprise stairs” may lead to your food arriving late.',
    needStairsYes: 'Yes',
    needStairsNo: 'No',
    haveAPromoCodePlaceholder: 'Have a promo code?',
    apply: 'Apply',
    freePromotionItem: '{item} has been added to your order',
    free: 'FREE'
  },

  paymentWallet: {
    loading: 'Loading...',
    defaultLabel: 'Default',
    addCardLabel: 'Add this card to my payment wallet',
    setDefaultCardLabel: 'Set as default',
    addCardTitle: 'Add new card',
    setDefaultCardTitle: 'Set as default card',
    processingTitle: 'Processing...',
    addCardSuccess: 'Card is successfully added.',
    setDefaultCardSuccess: 'Card is set as default.',
    removeCardConfirmation: 'Are you sure you want to remove your card ending with {last4}?',
    removeCardSuccess: 'Card is successfully removed.',
    alwaysSetAsDefaultMessage: 'Card will automatically be set as new default.'
  },

  sidePanel: {
    addOrderLabel: '+ Add another order',
    checkoutLabel: 'Go to checkout'
  },

  referralSelectorModal: {
    title: 'Assisted by one of our friendly<br>Sales Reps? <span class="NoWrap">Tell us who.</span>',
    thankYouNote: 'Thank you for sharing! Looking forward to serving you.',
    noOne: "I wasn't referred by anyone",
    submit: 'Confirm',
    submitting: 'Confirming...'
  }
}
