import Vue from 'vue'

function getInitialState() {
  return {
    readyForCheckout: false,
    orders: [],
    activeOrderId: 0,
    checkoutErrorsVisible: false
  }
}

export const order = {
  state: getInitialState(),

  mutations: {
    resetOrderState(state) {
      const initialState = getInitialState()
      for (var key in state) {
        state[key] = initialState[key]
      }
    },
    setReadyForCheckout(state, value) {
      state.readyForCheckout = value
    },
    setOrders(state, value) {
      state.orders = value
    },
    addOrder(state, { menuDetails, menuSections, selectedMenuPricing, selectedDate, selectedPax }) {
      const nextOrderId = state.orders.length > 0 ? state.orders[state.orders.length - 1].id + 1 : 1
      state.orders.push({
        id: nextOrderId,
        menuDetails,
        menuSections,
        selectedDate,
        selectedTime: null,
        selectedPax,
        selectedMenuPricing,
        selectedFilters: [],
        needStairs: null
      })
    },
    deleteOrder(state, index) {
      state.orders.splice(index, 1)
    },
    setOrderProp(state, { index, prop, value }) {
      Vue.set(state.orders[index], prop, value)
    },
    updateOrderMenuSectionMeals(state, { index, menuSectionIndex, value }) {
      Vue.set(
        state.orders[index].menuSections[menuSectionIndex],
        'selectedMenuSectionMeals',
        value
      )
    },
    setActiveOrderId(state, value) {
      state.activeOrderId = value
    },
    setCheckoutErrorsVisible(state, value) {
      state.checkoutErrorsVisible = value
    }
  },

  actions: {
    resetOrderState({ commit }) {
      commit('resetOrderState')
    },
    setReadyForCheckout({ commit }, value) {
      commit('setReadyForCheckout', value)
    },
    setOrders({ commit }, value) {
      commit('setOrders', value)
    },
    addOrder({ commit }, value) {
      commit('addOrder', value)
    },
    deleteOrder({ commit }, value) {
      commit('deleteOrder', value)
    },
    setOrderProp({ commit }, value) {
      commit('setOrderProp', value)
    },
    updateOrderMenuSectionMeals({ commit }, value) {
      commit('updateOrderMenuSectionMeals', value)
    },
    setActiveOrderId({ commit }, value) {
      commit('setActiveOrderId', value)
    },
    setCheckoutErrorsVisible({ commit }, value) {
      commit('setCheckoutErrorsVisible', value)
    }
  },

  getters: {
    getReadyForCheckout: state => state.readyForCheckout,
    getOrders: state => state.orders,
    getActiveOrder: state => state.orders.find(order => order.id === state.activeOrderId),
    getActiveOrderId: state => state.activeOrderId,
    getActiveOrderIndex: state => state.orders.findIndex(order => order.id === state.activeOrderId),
    getLastOrderId: state => {
      if (state.orders.length > 0) {
        return state.orders[state.orders.length - 1].id
      }
      return -1
    },
    getCheckoutErrorsVisible: state => state.checkoutErrorsVisible
  }
}
