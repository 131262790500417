export const timeSlots = {
  state: {
    disabledDates: []
  },

  mutations: {
    setDisabledDates(state, value) {
      state.disabledDates = value
    }
  },

  actions: {
    setDisabledDates({ commit }, value) {
      commit('setDisabledDates', value)
    }
  },

  getters: {
    getDisabledDates: state => state.disabledDates
  }
}
