import Vue from 'vue'
import Vuex from 'vuex'
import CreatePersistedState from 'vuex-persistedstate'
import { user } from './store/user'
import { menu } from './store/menu'
import { order } from './store/order'
import { fulfilment } from './store/fulfilment'
import { timeSlots } from './store/timeSlots'
import { locations } from './store/locations'
import { analyticsMiddleware } from './middlewares/analyticsMiddleware'

Vue.use(Vuex)

const persistedState = new CreatePersistedState({
  key: process.env.VUE_APP_STORAGE_KEY
})

export default new Vuex.Store({
  strict: true,
  plugins: [persistedState, analyticsMiddleware],
  modules: {
    menu,
    order,
    fulfilment,
    timeSlots,
    locations,
    user
  }
})
