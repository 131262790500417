import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/catering',
      name: 'catering',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "catering" */ './views/CateringPage.vue')
    },
    {
      path: '/catering/select-menu-items/:menuId?',
      name: 'catering-menu',
      component: () => import(/* webpackChunkName: "menu" */ './views/MenuPage.vue'),
      props: true
    },
    {
      path: '/catering/checkout',
      name: 'catering-checkout',
      component: () => import(/* webpackChunkName: "checkout" */ './views/CheckoutPage.vue')
    },
    {
      path: '/catering/success',
      name: 'catering-success',
      component: () => import(/* webpackChunkName: "success" */ './views/SuccessPage.vue')
    },
    {
      path: '/catering/welcome-success',
      name: 'catering-welcome-success',
      component: () => import(/* webpackChunkName: "success" */ './views/SuccessPage.vue')
    },

    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ './views/AboutPage.vue')
    },

    {
      path: '*',
      component: () => import('./views/404.vue')
    }
  ]
})
