import { EVENT_TYPES, LEAD_TIME_IN_DAYS, isMealBlocked } from '@/assets/js/order-helper'
import moment from 'moment-timezone'
import { isWithinTimeRange } from '@/assets/js/common'
import { startOfWorkingDaysLater } from '@/assets/js/datetime'

// Christmas 2023 add-on menus
// Season's Bounty — bundle of 2 (27)
// Season's Bounty — bundle of 3 (28)
const ADD_ON_MENU_IDS = {
  christmasBundleOfTwo: 27,
  christmasBundleOfThree: 28
}
const CHECKBOX_ADD_ON_MENU_IDS = [
  ADD_ON_MENU_IDS.christmasBundleOfTwo,
  ADD_ON_MENU_IDS.christmasBundleOfThree
]

export function isAddOnMenuVisible(addOnMenu, menuId, activeOrderEventType, selectedDate) {
  // hide add-on menu if menu is not linked
  if (!isAddOnMenuLinkedToMenu(addOnMenu, menuId)) {
    return false
  }

  // hide add-on menu if all add-ons in menu is unavailable by event type
  if (!addOnsAvailableForEventType(addOnMenu.addOns, activeOrderEventType)) {
    return false
  }

  let startDate
  let endDate
  if (addOnMenu.displayStartDate) {
    startDate = moment(addOnMenu.displayStartDate).toDate()
  }
  if (addOnMenu.displayEndDate) {
    endDate = moment(addOnMenu.displayEndDate).toDate()
  }
  if (startDate || endDate) {
    const selectedDateOrToday = selectedDate || startOfWorkingDaysLater(-LEAD_TIME_IN_DAYS)
    return isWithinTimeRange(selectedDateOrToday, startDate, endDate)
  }

  return true
}

function isAddOnMenuLinkedToMenu(addOnMenu, menuId) {
  // if linkedMenuIds is empty, all menus are linked
  if (addOnMenu.linkedMenuIds && addOnMenu.linkedMenuIds.length > 0) {
    return addOnMenu.linkedMenuIds.includes(menuId)
  }
  return true
}

function addOnsAvailableForEventType(addOns, activeOrderEventType) {
  return addOns.some((addOn) => isAddOnAvailableForEventType(addOn, activeOrderEventType))
}

function isAddOnAvailableForEventType(addOn, activeOrderEventType) {
  if (addOn.eventTypes !== null) {
    if (!EVENT_TYPES[addOn.eventTypes].includes(activeOrderEventType)) {
      return false
    }
  }
  return true
}

export function isAddOnMenuAvailable(addOnMenu, selectedTime, activeOrderEventType, selectedDate) {
  let isSelectedTimeValid = true
  if (addOnMenu.selectableStartTime || addOnMenu.selectableEndTime) {
    if (selectedTime) {
      const selectableStartTime = addOnMenuStartTime(addOnMenu, selectedTime, activeOrderEventType)
      const selectableEndTime = addOnMenuEndTime(addOnMenu, selectedTime, activeOrderEventType)

      isSelectedTimeValid = isWithinTimeRange(moment(selectedTime), selectableStartTime, selectableEndTime)
    }
  }
  if (!isSelectedTimeValid) {
    return false
  }

  if (selectedDate) {
    const startDate = addOnMenuStartDate(addOnMenu)
    const endDate = addOnMenuEndDate(addOnMenu)
    return isWithinTimeRange(selectedDate, startDate && startDate.toDate(), endDate && endDate.toDate())
  }
  return false
}

export function addOnMenuStartTime(addOnMenu, selectedTime, activeOrderEventType) {
  if (addOnMenu.selectableStartTime) {
    const selectableStartTime = moment(selectedTime || new Date())
      .startOf('day')
      .add(addOnMenu.selectableStartTime, 'seconds')

    switch (activeOrderEventType) {
      case 'MiniBuffet':
        return selectableStartTime.add(1, 'hour').add(15, 'minutes')
      default:
        return selectableStartTime.add(1, 'hour').add(45, 'minutes')
    }
  }
  return null
}

export function addOnMenuEndTime(addOnMenu, selectedTime, activeOrderEventType) {
  if (addOnMenu.selectableEndTime) {
    const selectableEndTime = moment(selectedTime || new Date())
      .startOf('day')
      .add(addOnMenu.selectableEndTime, 'seconds')

    switch (activeOrderEventType) {
      case 'MiniBuffet':
        return selectableEndTime.add(1, 'hour').add(15, 'minutes')
      default:
        return selectableEndTime.add(1, 'hour').add(45, 'minutes')
    }
  }
  return null
}

function addOnMenuStartDate(addOnMenu) {
  if (addOnMenu.selectableStartDate) {
    return moment(addOnMenu.selectableStartDate)
  }
  return null
}

function addOnMenuEndDate(addOnMenu) {
  if (addOnMenu.selectableEndDate) {
    return moment(addOnMenu.selectableEndDate)
  }
  return null
}

export function isAddOnAvailable(addOn, activeOrderEventType, selectedDate) {
  if (!isAddOnAvailableForEventType(addOn, activeOrderEventType)) {
    return false
  }

  if (!selectedDate) {
    return false
  }

  const isSelectedDateAvailable = isAddOnAvailableForSelectedDate(addOn, selectedDate)
  const isBlocked = isAddOnBlocked(addOn, selectedDate)
  return isSelectedDateAvailable && !isBlocked
}

function isAddOnAvailableForSelectedDate(addOn, selectedDate) {
  const startDate = addOn.startDate && moment(addOn.startDate).toDate()
  const endDate = addOn.endDate && moment(addOn.endDate).toDate()

  if (startDate && endDate) {
    return selectedDate >= startDate && selectedDate <= endDate
  }
  if (startDate) {
    return selectedDate >= startDate
  }
  if (endDate) {
    return selectedDate <= endDate
  }
  return true
}

function isAddOnBlocked(addOn, selectedDate) {
  if (addOn.item) {
    return isMealBlocked(addOn.item, selectedDate)
  }
  return false
}

export function isAddOnMenuSelectable(addOnMenu) {
  return CHECKBOX_ADD_ON_MENU_IDS.includes(addOnMenu.id)
}

export function addOnMenuMinSelection(addOnMenu) {
  switch (addOnMenu.id) {
    case ADD_ON_MENU_IDS.christmasBundleOfTwo:
      return 2
    case ADD_ON_MENU_IDS.christmasBundleOfThree:
      return 3
  }
  return null
}

export function addOnMenuMaxSelection(addOnMenu) {
  switch (addOnMenu.id) {
    case ADD_ON_MENU_IDS.christmasBundleOfTwo:
      return 2
    case ADD_ON_MENU_IDS.christmasBundleOfThree:
      return 3
  }
  return null
}
