import { BENTO_MENU_IDS, CNY_MENU_IDS, MENU_IDS, MINI_BUFFET_MENU_IDS, NATIONAL_DAY_MENU_IDS, PARTY_SET_MENU_ID, STANDARD_BUFFET_MENU_IDS } from '@/assets/js/order-helper'
import { getSameTimeInCurrentZone } from '@/assets/js/datetime'
import moment from 'moment-timezone'
import { CHRISTMAS_MENU_IDS } from './order-helper'

moment.tz.setDefault('Asia/Singapore')

// determines festive surcharge, and when it is applicable
export const FESTIVE_SURCHARGE = 2
export const FESTIVE_SURCHARGE_START_DATE = new Date('1 Dec 2023')
export const FESTIVE_SURCHARGE_END_DATE = new Date('30 Dec 2023')

// Chinese New Year
export const CNY_FESTIVE_SURCHARGE = 1
export const CNY_FESTIVE_SURCHARGE_1_START_DATE = new Date('29 Jan 2024')
export const CNY_FESTIVE_SURCHARGE_1_END_DATE = new Date('8 Feb 2024')
export const CNY_FESTIVE_SURCHARGE_2_START_DATE = new Date('14 Feb 2024')
export const CNY_FESTIVE_SURCHARGE_2_END_DATE = new Date('24 Feb 2024')

export const CNY_ALA_CARTE_MENU = {
  id: 'cny-2020-ala-carte',
  name: 'Chinese New Year À La Carte Menu',
  byline: 'Celebrate this beautiful beginning of spring with auspicious add-ons for your loved ones.',
  type: 'HighlightMenu',
  eventType: 'MiniBuffet',
  notice: 'Available from Jan 10 – Feb 10, excluding Jan 25–27<br>Last serving time on Jan 24 is at 2:00PM',
  terms:
    'Standalone add-ons will need to be self-collected at Grain <span>(5 Burn Road, #05-01, Singapore 369972)</span>',
  menuConfigs: [
    {
      deliveryFee: '0.0',
      id: 'cny-2020-ala-carte-config',
      menuId: 'cny-2020-ala-carte',
      menuSections: [],
      minimumAmountForFreeDelivery: null,
      minimumPax: 1,
      minimumPaxAdditionalCharges: [],
      pricePerPax: '0.0',
      summary: ''
    }
  ]
}

// Christmas
export const CHRISTMAS_PARTY_SET = 'Christmas Party Set'
export const CHRISTMAS_BUFFET_TO_GO = 'Christmas Buffet To Go'
export const CHRISTMAS_BUFFET = 'Christmas Buffet'

export const CHRISTMAS_MENUS = [CHRISTMAS_PARTY_SET, CHRISTMAS_BUFFET_TO_GO, CHRISTMAS_BUFFET]

export const CHRISTMAS_ALA_CARTE_MENU = {
  id: 'christmas-2019-ala-carte',
  name: 'Christmas À La Carte Menu',
  byline: 'This season of giving, give more Christmas goodness to your family and friends with our thoughtful add-ons.',
  type: 'HighlightMenu',
  eventType: 'MiniBuffet',
  notice: 'Available from Nov 18 - Dec 31',
  terms:
    'Standalone add-ons will need to be self-collected at Grain <span>(5 Burn Road, #05-01, Singapore 369972)</span>',
  menuConfigs: [
    {
      deliveryFee: '0.0',
      id: 'christmas-2019-ala-carte-config',
      menuId: 'christmas-2019-ala-carte',
      menuSections: [],
      minimumAmountForFreeDelivery: null,
      minimumPax: 1,
      minimumPaxAdditionalCharges: [],
      pricePerPax: '0.0',
      summary: ''
    }
  ]
}

export const CHRISTMAS_ALA_CARTE_MENU_LATEST_TIME = moment()
  .startOf('day')
  .add(18, 'hours')
  .add(30, 'minutes')
  .toDate()

export const CHRISTMAS_ADD_ONS_START_DATE = new Date('18 Nov 2019')
export const CHRISTMAS_ADD_ONS_END_DATE = new Date('26 Dec 2019')

export function isSameMenuGroup(menuId1, menuId2) {
  menuId1 = parseInt(menuId1)
  menuId2 = parseInt(menuId2)

  if (menuId1 === menuId2) {
    return true
  }

  return [BENTO_MENU_IDS].some(menuIds => {
    return menuIds.includes(menuId1) && menuIds.includes(menuId2)
  })
}

export function filterHighlightMenus(menus, menuId) {
  return menus.filter(menu => {
    return isSameMenuGroup(menuId, menu.id)
  })
}

export function sortedHighlightMenus(menus) {
  return menus.concat().sort((a, b) => {
    return CNY_MENU_IDS.indexOf(a.id) - CNY_MENU_IDS.indexOf(b.id)
  })
}

export function getFestiveSurcharge(order) {
  // apply to selected non-festive menus
  const selectedNonFestiveMenus = [MENU_IDS.classicBuffet, MENU_IDS.highTea, MENU_IDS.seminar, MENU_IDS.miniBuffet]
  if (selectedNonFestiveMenus.includes(order.menuDetails.id) && isFestiveSurchargePeriod(order.selectedDate)) {
    return FESTIVE_SURCHARGE
  }
  if (selectedNonFestiveMenus.includes(order.menuDetails.id) && isCNYFestiveSurchargePeriod(order.selectedDate)) {
    return CNY_FESTIVE_SURCHARGE
  }
  return 0
}

export function isCNYFestiveSurchargePeriod(selectedDate) {
  const date = getSameTimeInCurrentZone(selectedDate)
  if (
    (date >= CNY_FESTIVE_SURCHARGE_1_START_DATE && date <= CNY_FESTIVE_SURCHARGE_1_END_DATE) ||
    (date >= CNY_FESTIVE_SURCHARGE_2_START_DATE && date <= CNY_FESTIVE_SURCHARGE_2_END_DATE)
  ) {
    return true
  }
  return false
}

export function isFestiveSurchargePeriod(selectedDate) {
  const date = getSameTimeInCurrentZone(selectedDate)
  if (date >= FESTIVE_SURCHARGE_START_DATE && date <= FESTIVE_SURCHARGE_END_DATE) {
    return true
  }
  return false
}

export function isStandardBuffetMenu(menuId) {
  return STANDARD_BUFFET_MENU_IDS.includes(parseInt(menuId))
}

export function filterStandardBuffetMenus(menus) {
  const filteredMenus = menus.filter(menu => isStandardBuffetMenu(menu.id))
  return filteredMenus.map(menu => {
    return {
      ...menu,
      // only show the first config
      menuConfigs: [
        {
          ...menu.menuConfigs[0],
          pricePrefix: 'Starts from'
        }
      ]
    }
  })
}

export function isBentoMenu(menuId) {
  return BENTO_MENU_IDS.includes(parseInt(menuId))
}

export function filterBentoMenus(menus) {
  const filteredMenus = menus.filter(menu => isBentoMenu(menu.id))
  return filteredMenus.map(menu => {
    let priceSuffix
    switch (menu.id) {
      case MENU_IDS.bentoSignatures:
        priceSuffix = ''
        break
      case MENU_IDS.bento:
      case MENU_IDS.bentoTreats:
        priceSuffix = 'bento'
        break
      case MENU_IDS.bentoBundles:
        priceSuffix = 'bundle'
        break
      default:
        return menu
    }
    return {
      ...menu,
      menuConfigs: menu.menuConfigs.map(menuConfig => {
        return {
          ...menuConfig,
          pricePrefix: 'Starts from',
          priceSuffix
        }
      })
    }
  })
}

export function isMiniBufffetMenu(menuId) {
  return MINI_BUFFET_MENU_IDS.includes(parseInt(menuId))
}

export function filterMiniBuffetMenus(menus) {
  const filteredMenus = menus.filter(menu => isMiniBufffetMenu(menu.id))
  return filteredMenus.map(menu => {
    return {
      ...menu,
      // only show the first config
      menuConfigs: [
        {
          ...menu.menuConfigs[0],
          pricePrefix: 'Starts from'
        }
      ]
    }
  })
}

export function filterPartySetMenus(menus) {
  const partySetMenu = menus.find(menuCard => menuCard.id === PARTY_SET_MENU_ID)
  if (partySetMenu) {
    // HACK: different behavior from usual, hard-code for now
    return partySetMenu.menuConfigs.map(menuConfig => {
      const numOfCourses = menuConfig.summary.split(' ')[0]
      const serves = menuConfig.minimumPax === 10 ? '8–10' : '4–6'
      return {
        ...partySetMenu,
        menuConfigs: [menuConfig],
        name: `${numOfCourses}-course Party Set`,
        byline: `Serves ${serves} guests`
      }
    })
  }
  return []
}

export function filterChristmasMenus(menus) {
  return menus.filter((menu) => CHRISTMAS_MENU_IDS.includes(menu.id))
    .sort((a, b) => CHRISTMAS_MENU_IDS.indexOf(a.id) - CHRISTMAS_MENU_IDS.indexOf(b.id))
    .map((menu) => {
      switch (menu.id) {
        case 78:
          return {
            ...menu,
            name: 'Christmas Buffet',
            menuConfigs: menu.menuConfigs.map((menuConfig) => {
              switch (menuConfig.pricePerPax) {
                case '27.0':
                case '28.0':
                  return {
                    ...menuConfig,
                    name: 'Holiday Classic',
                    summary: '7 courses',
                    additionalSummary: '+ Drink (Christmas Buffet only)',
                    standardPricePerPax: '28.0'
                  }
                case '33.0':
                case '38.0':
                  return {
                    ...menuConfig,
                    name: "Santa's Signature",
                    summary: '8 courses',
                    additionalSummary: '+ Drink (Christmas Buffet only)',
                    standardPricePerPax: '38.0'
                  }
                case '42.0':
                case '48.0':
                  return {
                    ...menuConfig,
                    name: 'Yuletide Feast',
                    summary: '8 courses',
                    additionalSummary: '+ Drink and Polaroid Experience (Christmas Buffet only)',
                    standardPricePerPax: '48.0'
                  }
              }
              return menuConfig
            })
          }
        case 79:
          return {
            ...menu,
            name: 'Christmas Mini Buffet'
          }
      }
      return menu
    })
}

export function filterCnyMenus(menus) {
  return menus.filter((menu) => CNY_MENU_IDS.includes(menu.id))
    .sort((a, b) => CNY_MENU_IDS.indexOf(a.id) - CNY_MENU_IDS.indexOf(b.id))
    .map((menu) => {
      switch (menu.id) {
        case 81:
          return {
            ...menu,
            name: 'CNY Buffet',
            menuConfigs: menu.menuConfigs.map((menuConfig) => {
              switch (menuConfig.pricePerPax) {
                case '27.88':
                case '28.88':
                  return {
                    ...menuConfig,
                    name: 'Reunion Classic',
                    summary: '7 courses',
                    additionalSummary: '+ Drink (CNY Buffet only)',
                    standardPricePerPax: '28.88'
                  }
                case '32.88':
                case '38.88':
                  return {
                    ...menuConfig,
                    name: 'Spring Signature',
                    summary: '8 courses',
                    additionalSummary: '+ Drink (CNY Buffet only)',
                    standardPricePerPax: '38.88'
                  }
                case '42.88':
                case '48.88':
                  return {
                    ...menuConfig,
                    name: 'Fortune’s Feast',
                    summary: '9 courses',
                    additionalSummary: '+ Drink and Polaroid Experience (CNY Buffet only)',
                    standardPricePerPax: '48.88'
                  }
              }
              return menuConfig
            })
          }
        case 82:
          return {
            ...menu,
            name: 'CNY Mini Buffet'
          }
      }
      return menu
    })
}

export function isNationalDayMenu(menuId) {
  return NATIONAL_DAY_MENU_IDS.includes(parseInt(menuId))
}

export function filterNationalDayMenus(menus) {
  const filteredMenus = menus.filter(menu => isNationalDayMenu(menu.id))
  return filteredMenus.map(menu => {
    return {
      ...menu,
      // only show the first config
      menuConfigs: [
        {
          ...menu.menuConfigs[0],
          pricePrefix: 'Starts from'
        }
      ]
    }
  })
}
