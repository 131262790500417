import { getSameTimeInCurrentZone, startOfWorkingDaysLater } from '@/assets/js/datetime'
import { getFestiveSurcharge, isBentoMenu } from '@/assets/js/highlight-menu-helper'
import { addOnMenuMaxSelection, addOnMenuMinSelection, isAddOnMenuSelectable } from '@/assets/js/add-ons-helper'
import { isWithinTimeRange } from '@/assets/js/common'

import get from 'lodash/get'
import moment from 'moment-timezone'
import store from '@/store'

moment.tz.setDefault('Asia/Singapore')

export const LEAD_TIME_IN_DAYS = 4
// determines whether to use hard-coded timings, or the configurable ones from Papercut
export const OVERRIDE_TIME_SETTINGS = true
// 10:30AM-1:30PM
export const LUNCH_TIME_RANGE = [
  moment().startOf('day').add(10, 'hours').add(30, 'minutes').toDate(),
  moment().startOf('day').add(13, 'hours').add(30, 'minutes').toDate()
]
// 5:30PM-7:30PM
export const DINNER_TIME_RANGE = [
  moment().startOf('day').add(17, 'hours').add(30, 'minutes').toDate(),
  moment().startOf('day').add(19, 'hours').add(30, 'minutes').toDate()
]
// 8:00AM-10:30AM
export const BREAKFAST_TIME_RANGE = [
  moment().startOf('day').add(8, 'hours').add(0, 'minutes').toDate(),
  moment().startOf('day').add(10, 'hours').add(30, 'minutes').toDate()
]
// 2:00PM-4:30PM
export const TEA_TIME_RANGE = [
  moment().startOf('day').add(14, 'hours').add(0, 'minutes').toDate(),
  moment().startOf('day').add(16, 'hours').add(30, 'minutes').toDate()
]
export const TIME_RANGE_SERVICES = {
  BREAKFAST: 'breakfast',
  LUNCH: 'lunch',
  DINNER: 'dinner',
  TEA: 'tea'
}

export const EARLIEST_TIME = moment()
  .startOf('day')
  .add(10, 'hours')
  .add(30, 'minutes')
  .toDate()
export const LATEST_TIME = moment()
  .startOf('day')
  .add(19, 'hours')
  .add(30, 'minutes')
  .toDate()
export const MINI_BUFFET_LATEST_TIME = moment()
  .startOf('day')
  .add(19, 'hours')
  .add(30, 'minutes')
  .toDate()

export const GRAIN_BREAK_START_DATE = new Date('6 Jan 2020')
export const GRAIN_BREAK_END_DATE = new Date('9 Jan 2020')

export const SUNDAY_ANNOUNCEMENT_START_DATE = new Date('1 Jan 2020')
export const SUNDAY_ANNOUNCEMENT_END_DATE = new Date('11 Mar 2020')

// accepts Array:[Date, Array:[int, int], Array:[int, int]], where the inner Arrays represent startTime and endTime in the format of [hours, minutes] from start of the day; if null is provided, it will use the default
// Basically, this shows the opening hours for the date. [date, opening time, closing time]
export const DATES_WITH_MODIFIED_OPENING_HOURS = [
  [new Date('4 Feb 2019'), null, [13, 0]],
  [new Date('4 May 2019'), [10, 0], null],
  [new Date('1 Dec 2019'), null, [15, 45]], // https://app.asana.com/0/570128137826227/1150385470639865
  [new Date('24 Jan 2020'), null, [14, 45]]
]

export const MEAL_TAGS = {
  BREAKFAST: 'Breakfast',
  TEA: 'Tea',
  LUNCH: 'Lunch',
  DINNER: 'Dinner',
  CANAPE: 'Canapé',
  FINGER_FOOD: 'Finger food'
}

export const EVENT_TYPES = {
  0: ['Buffet', 'MiniBuffet'],
  1: ['Buffet'],
  2: ['MiniBuffet']
}

export const MENU_IDS = {
  classicBuffet: 5,
  highTea: 6,
  seminar: 7,
  miniBuffet: 14,
  miniHighTea: 21,
  healthyBuffet: 12,
  cnyBuffet: 81,
  cnyMiniBuffet: 82,
  cnyBundle: 20,
  cnyAlaCarteAddOns: 83,
  weddingClassic: 29,
  weddingSignature: 30,
  weddingPremium: 31,
  bentoSignatures: 32,
  bento: 33,
  bentoTreats: 34,
  bentoBundles: 35,
  christmasPartySet: 59,
  christmasMiniBuffet: 60,
  christmasAlaCarteAddOns: 45,
  nationalDayBuffet: 25,
  nationalDayMiniBuffet: 73,
  nationalDayHighTea: 71,
  nationalDayMiniHighTea: 72
}

export const CNY_MENU_IDS = [MENU_IDS.cnyBuffet, MENU_IDS.cnyMiniBuffet]

// Christmas 2023 menus
export const CHRISTMAS_MENU_IDS = [78, 79]

export const PARTY_SET_MENU_ID = 48

export const WEDDING_MENU_IDS = [MENU_IDS.weddingClassic, MENU_IDS.weddingSignature, MENU_IDS.weddingPremium]

export const BENTO_MENU_IDS = [MENU_IDS.bentoSignatures, MENU_IDS.bento, MENU_IDS.bentoTreats, MENU_IDS.bentoBundles]

export const STANDARD_BUFFET_MENU_IDS = [MENU_IDS.classicBuffet, MENU_IDS.highTea, MENU_IDS.seminar]

export const MINI_BUFFET_MENU_IDS = [MENU_IDS.miniBuffet, MENU_IDS.miniHighTea]

export const NATIONAL_DAY_MENU_IDS = [
  MENU_IDS.nationalDayBuffet,
  MENU_IDS.nationalDayMiniBuffet,
  MENU_IDS.nationalDayHighTea,
  MENU_IDS.nationalDayMiniHighTea
]

function getDisabledDates() {
  return store.getters.getDisabledDates
}

function getMenuDisabledDates() {
  return store.getters.getMenuDisabledDates
}

function getCombinedDisabledDates() {
  return getDisabledDates().concat(getMenuDisabledDates())
}

export function isMenuVisible(menu) {
  if (menu.displayStartDate || menu.displayEndDate) {
    const today = new Date()
    return isWithinTimeRange(
      today,
      menu.displayStartDate && new Date(menu.displayStartDate),
      menu.displayEndDate && endOfDay(menu.displayEndDate)
    )
  }
  return true
}

function endOfDay(datestring) {
  return moment(datestring, 'DD MMM YYYY')
    .endOf('day')
    .toDate()
}

export function getOrderMenuDetailsClone(menuDetails) {
  const newMenuDetails = Object.assign({}, menuDetails)
  delete newMenuDetails['menuConfigs']
  return newMenuDetails
}

export function getOrderMenuSectionsClone(menuSections) {
  return menuSections.map(menuSection => {
    const newMenuSection = Object.assign({}, menuSection)
    const selectOneOnly = menuSection.minAmountRequired === 1 && menuSection.maxAmountRequired === 1
    newMenuSection.selectedMenuSectionMeals = selectOneOnly ? null : []
    return newMenuSection
  })
}

export function getCategoriesCountMapper(order) {
  const mapper = {}
  if (typeof order === 'object') {
    order.menuSections.forEach(menuSection => {
      if (menuSection.category) {
        if (mapper[menuSection.category] === undefined) {
          mapper[menuSection.category] = {
            maxAmountRequired: 0,
            selectedCount: 0
          }
        }
        mapper[menuSection.category].maxAmountRequired += menuSection.maxAmountRequired
        if (menuSection.selectedMenuSectionMeals) {
          mapper[menuSection.category].selectedCount += filterSelectedMenuSectionMeals(
            menuSection.selectedMenuSectionMeals,
            order.selectedDate
          ).length
        }
      }
    })
  }
  return mapper
}

export function isMenuAddOnMenu(order, addOnMenus) {
  return addOnMenus.some(addOnMenu => addOnMenu.name === order.menuDetails.name)
}

export function isAddOnMenuValid(order, addOnMenus) {
  return addOnMenus.every((addOnMenu) => {
    if (!isMinimumPaxAddOnMenuValid(addOnMenu, order)) {
      return false
    }
    if (!isSelectableAddOnMenuValid(addOnMenu, order)) {
      return false
    }
    return true
  })
}

export function isMinimumPaxAddOnMenuValid(addOnMenu, order) {
  if (addOnMenu.name === order.menuDetails.name) {
    // return false early, if no add-ons are selected, and minimum pax is more than 0
    if (!order.selectedAddOns || order.selectedAddOns.length === 0) {
      if (order.selectedMenuPricing.minimumPax && order.selectedMenuPricing.minimumPax > 0) {
        return false
      }
    }
    const addOnIds = addOnMenu.addOns.map(addOn => addOn.id)
    const selectedAddOnIds = order.selectedAddOns.filter(selectedAddOn => addOnIds.includes(selectedAddOn.id))
    const totalQuantity = selectedAddOnIds.reduce((total, addOn) => total + addOn.quantity, 0)
    return totalQuantity >= order.selectedMenuPricing.minimumPax
  }
  return true
}

export function isSelectableAddOnMenuValid(addOnMenu, order) {
  if (isAddOnMenuSelectable(addOnMenu)) {
    const addOnIds = addOnMenu.addOns.map(addOn => addOn.id)
    const selectedAddOnIds = order.selectedAddOns.filter(selectedAddOn => addOnIds.includes(selectedAddOn.id))
    const totalQuantity = selectedAddOnIds.reduce((total, addOn) => total + addOn.quantity, 0)
    if (totalQuantity > 0 && totalQuantity < addOnMenuMinSelection(addOnMenu)) {
      return false
    }
    if (totalQuantity > addOnMenuMaxSelection(addOnMenu)) {
      return false
    }
  }
  return true
}

export function isOrderValid(order) {
  if (
    !order.selectedDate ||
    !order.selectedTime ||
    !isTimeSelectedValid(order.selectedTime, order.selectedDate, order.menuDetails.eventType, order) ||
    !isSeminarMenuTimesSelectedValid(order) ||
    !isBentoBundlesTimesSelectedValid(order) ||
    !isTimeSlotsAvailable(order) ||
    !isDateSelectedValid(order) ||
    !((order.selectedPax || null) !== null) ||
    !isPaxSelectedValid(order) ||
    !order.selectedMenuPricing ||
    !hasSelectedMeals(order) ||
    !(order.menuSections.length > 0 || (order.selectedAddOns && order.selectedAddOns.length > 0))
  ) {
    console.log([
      [!order.selectedDate, 1],
      [!order.selectedTime, 2],
      [!isTimeSelectedValid(order.selectedTime, order.selectedDate, order.menuDetails.eventType, order), 3],
      [!isSeminarMenuTimesSelectedValid(order), 4],
      [!isBentoBundlesTimesSelectedValid(order), 5],
      [!isTimeSlotsAvailable(order), 6],
      [!isDateSelectedValid(order), 7],
      [!((order.selectedPax || null) !== null), 8],
      [!isPaxSelectedValid(order), 9],
      [!order.selectedMenuPricing, 10],
      [!hasSelectedMeals(order), 11],
      [!(order.menuSections.length > 0 || (order.selectedAddOns && order.selectedAddOns.length > 0)), 12]
    ])
    return false
  }
  return true
}

export function isDeliveryAddressValid(customer = null) {
  if (customer) {
    const matchingLocationConfig = findLocationConfig(get(customer, 'deliveryAddress.postalCode'))
    if (matchingLocationConfig && matchingLocationConfig.configType === 'not_allowed') {
      return false
    }
  }
  // mark as valid if no customer object provided
  return true
}

export function getSpecialLocationDeliveryCharge(order, customer = null) {
  if (customer) {
    const matchingLocationConfig = findLocationConfig(get(customer, 'deliveryAddress.postalCode'))
    if (matchingLocationConfig && matchingLocationConfig.configType === 'special_delivery_charge') {
      const specialLocationDeliveryCharge = store.getters.specialLocationDeliveryCharge
      if (order.menuDetails.name.includes('Seminar')) {
        if (order.selectedAddOns && order.selectedAddOns.some(addOn => addOn.item.name.includes('Full Day Seminar'))) {
          return specialLocationDeliveryCharge.fullDaySeminar
        }
        return specialLocationDeliveryCharge.halfDaySeminar
      }
      return specialLocationDeliveryCharge.default
    }
  }
  return null
}

function findLocationConfig(postalCode) {
  const locationConfigs = store.getters.locationConfigs
  return locationConfigs.find((config) => postalCode === config.postalCode)
}

export function getEarliestTime(date, order = null) {
  const earliestTimeAfterBlocks = getDisabledTime(date, 'earliest')
  if (earliestTimeAfterBlocks) return earliestTimeAfterBlocks

  if (!OVERRIDE_TIME_SETTINGS) {
    const time = get(order, 'selectedMenuPricing.earliestServingTime', null)
    if (time) {
      const earliestTimeDate = new Date(time)
      const currentDay = new Date()
      return new Date(currentDay.setHours(earliestTimeDate.getHours(), earliestTimeDate.getMinutes(), 0, 0))
    }
  } else {
    const availableTimeRanges = getAvailableTimeRanges(order)
    if (availableTimeRanges.length > 0) {
      return new Date(Math.min(...availableTimeRanges.map((timeRange) => timeRange[0])))
    }
  }
  return EARLIEST_TIME
}

export function getLatestTime(date, eventType, order = null) {
  const latestTimeAfterBlocks = getDisabledTime(date, 'latest', eventType)
  if (latestTimeAfterBlocks) return latestTimeAfterBlocks

  if (!OVERRIDE_TIME_SETTINGS) {
    const time = get(order, 'selectedMenuPricing.latestServingTime', null)
    if (time) {
      const latestTimeDate = new Date(time)
      const currentDay = new Date()
      return new Date(currentDay.setHours(latestTimeDate.getHours(), latestTimeDate.getMinutes(), 0, 0))
    }
  } else {
    const availableTimeRanges = getAvailableTimeRanges(order)
    if (availableTimeRanges.length > 0) {
      return new Date(Math.max(...availableTimeRanges.map((timeRange) => timeRange[1])))
    }
  }
  return eventType === 'MiniBuffet' ? MINI_BUFFET_LATEST_TIME : LATEST_TIME
}

function getDisabledTime(date, type, eventType = null) {
  if (date) {
    const selectedDate = getSameTimeInCurrentZone(date)
    for (var i = 0; i < DATES_WITH_MODIFIED_OPENING_HOURS.length; i++) {
      const disabledDate = DATES_WITH_MODIFIED_OPENING_HOURS[i][0]
      const hoursMinutes = DATES_WITH_MODIFIED_OPENING_HOURS[i][type === 'earliest' ? 1 : 2]
      if (
        hoursMinutes &&
        hoursMinutes.length > 1 &&
        selectedDate.getFullYear() === disabledDate.getFullYear() &&
        selectedDate.getMonth() === disabledDate.getMonth() &&
        selectedDate.getDate() === disabledDate.getDate()
      ) {
        let time = moment()
          .startOf('day')
          .add(hoursMinutes[0], 'hours')
          .add(hoursMinutes[1], 'minutes')
        if (type === 'latest' && eventType === 'MiniBuffet') {
          time = time.add(-30, 'minutes')
        }
        return time.toDate()
      }
    }
  }
  return null
}

export function isDateSelectedValid(order) {
  const minDate = getMinDate(order)
  const maxDate = getMaxDate(order)
  const selectedDate = moment(order.selectedDate)
    .startOf('day')
    .toDate()
  if (
    selectedDate <
    moment(minDate)
      .startOf('day')
      .toDate()
  ) {
    return false
  }
  if (
    maxDate &&
    selectedDate >
      moment(maxDate)
        .startOf('day')
        .toDate()
  ) {
    return false
  }
  return !isDisabledDate(selectedDate, getCombinedDisabledDates())
}

export function isDisabledDate(date, disabledDates = null) {
  if (!disabledDates) {
    disabledDates = getDisabledDates()
  }
  date = moment(date)
    .startOf('day')
    .toDate()
  if (withinDisabledDates(date, disabledDates)) {
    return true
  }
  return false
}

function withinDisabledDates(date, ranges) {
  for (var i = 0; i < ranges.length; i++) {
    const disabledDate = ranges[i]
    if (Array.isArray(disabledDate)) {
      if (date >= disabledDate[0] && date <= disabledDate[1]) {
        return true
      }
    } else {
      if (date.getTime() === disabledDate.getTime()) {
        return true
      }
    }
  }
  return false
}

export function getMinDate(order = null) {
  const startDate = new Date()
  let leadTime = getSameTimeInCurrentZone(startOfWorkingDaysLater(LEAD_TIME_IN_DAYS, startDate))
  const disabledDates = order ? getCombinedDisabledDates() : getDisabledDates()
  while (isDisabledDate(leadTime, disabledDates)) {
    leadTime = moment(leadTime)
      .add(1, 'day')
      .toDate()
  }
  let minDate = leadTime
  if (order && order.menuDetails && order.menuDetails.selectableStartDate) {
    const startDate = new Date(order.menuDetails.selectableStartDate)
    if (startDate > leadTime) {
      minDate = startDate
    }
  }
  return minDate
}

export function getMaxDate(order = null) {
  if (order && order.menuDetails.selectableEndDate) {
    return new Date(order.menuDetails.selectableEndDate)
  }
  return null
}

export function getAvailableTimeRanges(order = null) {
  if (order) {
    if (
      order.menuDetails.type === 'SeminarMenu' ||
      order.menuDetails.id === MENU_IDS.bentoBundles ||
      order.menuDetails.name.includes('Bento Bundles')
    ) {
      return [
        BREAKFAST_TIME_RANGE,
        LUNCH_TIME_RANGE,
        TEA_TIME_RANGE
      ]
    } else {
      const availableTimeRanges = []
      if (order.menuDetails.tags.includes(MEAL_TAGS.BREAKFAST)) {
        availableTimeRanges.push(BREAKFAST_TIME_RANGE)
      }
      if (order.menuDetails.tags.includes(MEAL_TAGS.LUNCH)) {
        availableTimeRanges.push(LUNCH_TIME_RANGE)
      }
      if (order.menuDetails.tags.includes(MEAL_TAGS.TEA)) {
        availableTimeRanges.push(TEA_TIME_RANGE)
      }
      if (order.menuDetails.tags.includes(MEAL_TAGS.DINNER)) {
        availableTimeRanges.push(DINNER_TIME_RANGE)
      }
      if (availableTimeRanges.length > 0) {
        return availableTimeRanges
      }
    }
  }
  return [LUNCH_TIME_RANGE, DINNER_TIME_RANGE]
}

export function getAvailableTimeRangeServices(order = null) {
  if (order) {
    if (order.menuDetails.type === 'SeminarMenu') {
      return [
        TIME_RANGE_SERVICES.BREAKFAST,
        TIME_RANGE_SERVICES.LUNCH,
        TIME_RANGE_SERVICES.TEA
      ]
    } else {
      const availableTimeRangeServices = []
      if (order.menuDetails.tags.includes(MEAL_TAGS.BREAKFAST)) {
        availableTimeRangeServices.push(TIME_RANGE_SERVICES.BREAKFAST)
      }
      if (order.menuDetails.tags.includes(MEAL_TAGS.LUNCH)) {
        availableTimeRangeServices.push(TIME_RANGE_SERVICES.LUNCH)
      }
      if (order.menuDetails.tags.includes(MEAL_TAGS.TEA)) {
        availableTimeRangeServices.push(TIME_RANGE_SERVICES.TEA)
      }
      if (order.menuDetails.tags.includes(MEAL_TAGS.DINNER)) {
        availableTimeRangeServices.push(TIME_RANGE_SERVICES.DINNER)
      }
      if (availableTimeRangeServices.length > 0) {
        return availableTimeRangeServices
      }
    }
  }
  return [TIME_RANGE_SERVICES.LUNCH, TIME_RANGE_SERVICES.DINNER]
}

export function isTimeSelectedValid(time, date, eventType, order = null) {
  const selectedTime = new Date(time)
  if (selectedTime < getEarliestTime(date, order)) {
    return false
  } else if (selectedTime > getLatestTime(date, eventType, order)) {
    return false
  } else if (OVERRIDE_TIME_SETTINGS && time) {
    const availableTimeRanges = getAvailableTimeRanges(order)
    const withinTimeRange = availableTimeRanges.some(
      (timeRange) => selectedTime >= timeRange[0] && selectedTime <= timeRange[1]
    )
    if (!withinTimeRange) return false
  }
  return true
}

export function isSeminarMenuTimesSelectedValid(order) {
  if (order.menuDetails.type !== 'SeminarMenu') {
    return true
  }
  const menuSections = order.selectedMenuPricing.menuSections
  const selectedDate = order.selectedDate
  const eventType = order.menuDetails.eventType
  let morningBreakValid = true
  let afternoonBreakValid = true
  if (menuSections[0].name.indexOf('Lunch') === -1) {
    morningBreakValid =
      order.selectedMorningBreakTime && isTimeSelectedValid(order.selectedMorningBreakTime, selectedDate, eventType, order)
  }
  if (menuSections[menuSections.length - 1].name.indexOf('Lunch') === -1) {
    afternoonBreakValid =
      order.selectedAfternoonBreakTime && isTimeSelectedValid(order.selectedAfternoonBreakTime, selectedDate, eventType, order)
  }
  return morningBreakValid && afternoonBreakValid
}

function isBentoBundlesTimesSelectedValid(order) {
  if (!order.menuDetails.name.includes('Bento Bundles')) {
    return true
  }
  const selectedDate = order.selectedDate
  const eventType = order.menuDetails.eventType
  let morningBreakValid = true
  let afternoonBreakValid = true
  if (
    order.selectedAddOns.some(
      addOn => addOn.item.name.includes('Morning Bundle') || addOn.item.name.includes('Full Day Bundle')
    )
  ) {
    morningBreakValid =
      order.selectedMorningBreakTime && isTimeSelectedValid(order.selectedMorningBreakTime, selectedDate, eventType, order)
  }
  if (
    order.selectedAddOns.some(
      addOn => addOn.item.name.includes('Afternoon Bundle') || addOn.item.name.includes('Full Day Bundle')
    )
  ) {
    afternoonBreakValid =
      order.selectedAfternoonBreakTime && isTimeSelectedValid(order.selectedAfternoonBreakTime, selectedDate, eventType, order)
  }
  return morningBreakValid && afternoonBreakValid
}

function getEventTypeKey(order) {
  if (order.isSelfPickup) {
    return 'selfPickup'
  }
  const eventType = order.menuDetails.eventType
  return `${eventType.slice(0, 1).toLowerCase()}${eventType.slice(1)}`
}

export function isTimeSlotsAvailable(order, selectedTime) {
  if (order.timeSlotsAvailability && selectedTime) {
    const selectedTimeLabel = moment(selectedTime).format('h:mmA')
    return get(order.timeSlotsAvailability[selectedTimeLabel.toLowerCase()], getEventTypeKey(order), false)
  }
  return true
}

export function getAllTimeSlots(order) {
  if (order.timeSlotsAvailability) {
    return Object.keys(order.timeSlotsAvailability)
  }
  return null
}

export function getPreviousAvailableTimeSlot(order, selectedTime, earliestTime) {
  const allTimeSlots = getAllTimeSlots(order)
  if (allTimeSlots && selectedTime) {
    const selectedTimeLabel = moment(selectedTime).format('h:mmA')
    const eventType = getEventTypeKey(order)

    let index = allTimeSlots.findIndex(timeSlot => timeSlot === selectedTimeLabel.toLowerCase())
    let timeLabel
    while (index > 0) {
      index--
      timeLabel = allTimeSlots[index]
      if (moment(`${earliestTime.toDateString()} ${timeLabel}`, 'ddd MMM DD YYYY h:mmA').toDate() < earliestTime) {
        continue
      }
      if (order.timeSlotsAvailability[timeLabel][eventType]) {
        return timeLabel.toUpperCase()
      }
    }
  }
  return null
}

export function getNextAvailableTimeSlot(order, selectedTime, latestTime) {
  const allTimeSlots = getAllTimeSlots(order)
  if (allTimeSlots && selectedTime) {
    const selectedTimeLabel = moment(selectedTime).format('h:mmA')
    const eventType = getEventTypeKey(order)

    let index = allTimeSlots.findIndex(timeSlot => timeSlot === selectedTimeLabel.toLowerCase())
    let timeLabel
    while (index < allTimeSlots.length - 1) {
      index++
      timeLabel = allTimeSlots[index]
      if (moment(`${latestTime.toDateString()} ${timeLabel}`, 'ddd MMM DD YYYY h:mma').toDate() > latestTime) {
        break
      }
      if (order.timeSlotsAvailability[timeLabel][eventType]) {
        return timeLabel.toUpperCase()
      }
    }
  }
  return null
}

export function isPaxSelectedValid(order) {
  if (order.selectedPax < getMinimumPax(order)) {
    return false
  }
  const paxMultiple = 5
  if (order.selectedPax % paxMultiple !== 0) {
    if (order.selectedMenuPricing.minimumPax !== 1 || order.selectedPax !== 1) {
      return false
    }
  }
  return true
}

export function getMinimumPax(order) {
  if (get(order, 'selectedMenuPricing')) {
    if (get(order, 'selectedMenuPricing.minimumPaxAdditionalCharges.length', 0) > 0) {
      let minimum = order.selectedMenuPricing.minimumPax
      order.selectedMenuPricing.minimumPaxAdditionalCharges.forEach(minimumPaxAdditionalCharge => {
        minimum = Math.min(minimum, minimumPaxAdditionalCharge.minimumPax)
      })
      return minimum
    }
    return order.selectedMenuPricing.minimumPax
  }
  return 10
}

export function hasSelectedMeals(order) {
  let selected = true
  order.menuSections.forEach(menuSection => {
    if (menuSection.category === null) {
      if (!(menuSection.minAmountRequired === 0 && menuSection.maxAmountRequired === 0)) {
        const selectedMenuSectionMeals = filterSelectedMenuSectionMeals(
          menuSection.selectedMenuSectionMeals,
          order.selectedDate
        )
        if (
          selectedMenuSectionMeals.length < menuSection.minAmountRequired ||
          selectedMenuSectionMeals.length > menuSection.maxAmountRequired
        ) {
          selected = false
        }
      }
    } else {
      const categoriesCountMapper = getCategoriesCountMapper(order)
      const categoryCount = categoriesCountMapper[menuSection.category]
      if (categoryCount.selectedCount !== categoryCount.maxAmountRequired) {
        selected = false
      }
    }
  })
  return selected
}

export function filterSelectedMenuSectionMeals(selectedMenuSectionMeals, orderSelectedDate) {
  const menuSectionMeals = Array.isArray(selectedMenuSectionMeals)
    ? selectedMenuSectionMeals
    : [selectedMenuSectionMeals]
  return menuSectionMeals.filter(menuSectionMeal => {
    if (menuSectionMeal && menuSectionMeal.meal) {
      return !isMealBlocked(menuSectionMeal.meal, orderSelectedDate)
    }
    return false
  })
}

export function isMealBlocked(meal, orderSelectedDate) {
  if (!meal.mealBlocks || meal.mealBlocks.length === 0) return false

  const selectedDate = new Date(orderSelectedDate).setHours(0, 0, 0, 0)
  for (var i = 0; i < meal.mealBlocks.length; i++) {
    var mealBlock = meal.mealBlocks[i]
    var startDate = new Date(mealBlock.startDate).setHours(0, 0, 0, 0)
    if (startDate <= selectedDate) {
      if (!mealBlock.endDate) return true

      var endDate = new Date(mealBlock.endDate).setHours(0, 0, 0, 0)
      if (endDate >= selectedDate) return true
    }
  }
  return false
}

export function getMinimumPaxSurcharge(order) {
  if (
    get(order, 'selectedMenuPricing.minimumPaxAdditionalCharges.length', 0) > 0 &&
    order.selectedPax < order.selectedMenuPricing.minimumPax
  ) {
    let additionalCharge = null
    order.selectedMenuPricing.minimumPaxAdditionalCharges
      .concat()
      .sort((a, b) => {
        return b.minimumPax - a.minimumPax
      })
      .forEach(minimumPaxAdditionalCharge => {
        if (minimumPaxAdditionalCharge.minimumPax >= order.selectedPax) {
          additionalCharge = minimumPaxAdditionalCharge.pricePerPax
        }
      })
    return parseFloat(additionalCharge)
  }
  return null
}

export function getAdditionalChargeItems(order) {
  const items = []
  order.menuSections.forEach(menuSection => {
    if (menuSection.selectedMenuSectionMeals) {
      if (Array.isArray(menuSection.selectedMenuSectionMeals)) {
        menuSection.selectedMenuSectionMeals.forEach(selectedMenuSectionMeal => {
          if (selectedMenuSectionMeal.additionalChargePerPax) {
            items.push(selectedMenuSectionMeal)
          }
        })
      } else {
        if (menuSection.selectedMenuSectionMeals.additionalChargePerPax) {
          items.push(menuSection.selectedMenuSectionMeals)
        }
      }
    }
  })
  return items
}

export function getTotalAdditionalChargesPerPax(order, additionalChargeItems = null) {
  if (!additionalChargeItems) {
    additionalChargeItems = getAdditionalChargeItems(order)
  }
  return additionalChargeItems.reduce((accumulator, selectedMenuSectionMeal) => {
    return accumulator + parseFloat(selectedMenuSectionMeal.additionalChargePerPax)
  }, 0)
}

export function getDeliveryFee(order, minimumPaxSurcharge = null, totalAdditionalChargesPerPax = null, customer = null) {
  if (order.isSelfPickup) {
    return 0
  }
  const minimumAmountForFreeDelivery = parseFloat(order.selectedMenuPricing.minimumAmountForFreeDelivery)
  if (minimumAmountForFreeDelivery) {
    const foodSubtotal = parseFloat(getFoodTotal(order, minimumPaxSurcharge, totalAdditionalChargesPerPax))
    if (foodSubtotal >= minimumAmountForFreeDelivery) {
      return 0
    }
  }
  // check for special location delivery charge
  if (customer) {
    const specialDeliveryCharge = getSpecialLocationDeliveryCharge(order, customer)
    if (specialDeliveryCharge) {
      return specialDeliveryCharge
    }
  }
  // charge $10 more for full day bundles
  if (order.menuDetails.name.includes('Seminar')) {
    if (order.selectedAddOns && order.selectedAddOns.some(addOn => addOn.item.name.includes('Full Day Seminar'))) {
      return parseFloat(order.selectedMenuPricing.deliveryFee) + 10
    }
  }
  return parseFloat(order.selectedMenuPricing.deliveryFee)
}

export function getStairsCost(order) {
  const stairsSurcharge = 90
  return order.needStairs && order.menuDetails.eventType !== 'MiniBuffet' ? stairsSurcharge : 0
}

export function getSelectedAddOnsCost(order) {
  let totalAddOnCost = 0
  if (order.selectedAddOns) {
    const festiveSurcharge = isBentoMenu(order.menuDetails.id) ? getFestiveSurcharge(order) : 0
    order.selectedAddOns.forEach(selectedAddOn => {
      totalAddOnCost += (selectedAddOn.price + festiveSurcharge) * selectedAddOn.quantity
      if (selectedAddOn.additionalFee) totalAddOnCost += parseFloat(selectedAddOn.additionalFee)
    })
  }
  return parseFloat(totalAddOnCost.toFixed(2))
}

export function getSubtotal(order, minimumPaxSurcharge = null, totalAdditionalChargesPerPax = null) {
  const foodTotal = parseFloat(getFoodTotal(order, minimumPaxSurcharge, totalAdditionalChargesPerPax))
  const addOnsCost = getSelectedAddOnsCost(order)
  return foodTotal - addOnsCost
}

export function getFoodTotal(order, minimumPaxSurcharge = null, totalAdditionalChargesPerPax = null) {
  let foodSubtotal = 0
  if ((order.selectedPax || null) != null && order.selectedMenuPricing) {
    if (!minimumPaxSurcharge) {
      minimumPaxSurcharge = getMinimumPaxSurcharge(order)
    }
    if (!totalAdditionalChargesPerPax) {
      totalAdditionalChargesPerPax = getTotalAdditionalChargesPerPax(order)
    }
    const festiveSurcharge = isBentoMenu(order.menuDetails.id) ? 0 : getFestiveSurcharge(order)
    const pricePerPax =
      parseFloat(order.selectedMenuPricing.pricePerPax) +
      minimumPaxSurcharge +
      totalAdditionalChargesPerPax +
      festiveSurcharge
    foodSubtotal = pricePerPax * order.selectedPax
  }
  foodSubtotal += getSelectedAddOnsCost(order)
  return foodSubtotal.toFixed(2)
}

export function getOrderTotal(order, minimumPaxSurcharge = null, totalAdditionalChargesPerPax = null, customer = null) {
  const foodSubtotal = parseFloat(getFoodTotal(order, minimumPaxSurcharge, totalAdditionalChargesPerPax))
  const deliveryFee = getDeliveryFee(order, minimumPaxSurcharge, totalAdditionalChargesPerPax, customer)
  const stairsCost = getStairsCost(order)
  return (foodSubtotal + deliveryFee + stairsCost).toFixed(2)
}

export function getTotal(orders, discount, customer = null) {
  let total = orders.reduce((accumulator, order) => {
    return accumulator + parseFloat(getOrderTotal(order, null, null, customer))
  }, 0)
  if (discount && !isNaN(discount)) total = total - discount
  return total.toFixed(2)
}

export function getCostWithGst(cost, date) {
  return (cost * (1 + getTaxRate(date))).toFixed(2)
}

export function getCostWithoutGst(costWithGst, date) {
  return (costWithGst / (1 + getTaxRate(date))).toFixed(2)
}

export function getTaxRate(date) {
  date = date ? new Date(date) : new Date()
  if (date.getFullYear() === 2023) {
    return 0.08
  } else if (date.getFullYear() >= 2024) {
    return 0.09
  } else {
    return 0.07
  }
}
