function getInitialState() {
  return {
    customer: {
      name: null,
      lastName: null,
      contactNumber: null,
      email: null,
      deliveryAddress: {
        address: null,
        postalCode: null,
        floor: null,
        unitNo: null,
        blkTower: null
      },
      companyName: null,
      billingAddress: {
        name: null,
        lastName: null,
        address: null,
        postalCode: null,
        floor: null,
        unitNo: null,
        blkTower: null
      }
    },
    selectedDeliveryAddress: null,
    selectedBillingAddress: null,
    secondaryContactName: null,
    secondaryContactNumber: null,
    billingAddressSameAsDeliveryAddress: true,
    venueOpenAtSetupTime: null,
    isCorporateOrder: false,
    isDisposablesRequired: null,
    createAccount: true,
    specialInstructions: null,
    promoCode: null,
    checkoutComplete: false,
    salesOrder: null,
    orderPaid: false
  }
}

export const fulfilment = {
  state: getInitialState(),

  mutations: {
    resetFulfilmentState(state) {
      const initialState = getInitialState()
      for (var key in state) {
        state[key] = initialState[key]
      }
    },
    setCustomer(state, value) {
      state.customer = value
    },
    setSelectedDeliveryAddress(state, value) {
      state.selectedDeliveryAddress = value
    },
    setSelectedBillingAddress(state, value) {
      state.selectedBillingAddress = value
    },
    setSecondaryContactName(state, value) {
      state.secondaryContactName = value
    },
    setSecondaryContactNumber(state, value) {
      state.secondaryContactNumber = value
    },
    setBillingAddressSameAsDeliveryAddress(state, value) {
      state.billingAddressSameAsDeliveryAddress = value
    },
    setVenueOpenAtSetupTime(state, value) {
      state.venueOpenAtSetupTime = value
    },
    setIsCorporateOrder(state, value) {
      state.isCorporateOrder = value
    },
    setIsDisposablesRequired(state, value) {
      state.isDisposablesRequired = value
    },
    setCreateAccount(state, value) {
      state.createAccount = value
    },
    setSpecialInstructions(state, value) {
      state.specialInstructions = value
    },
    setPromoCode(state, value) {
      state.promoCode = value
    },
    setCheckoutComplete(state, value) {
      state.checkoutComplete = value
    },
    setSalesOrder(state, value) {
      state.salesOrder = value
    },
    setOrderPaid(state, value) {
      state.orderPaid = value
    }
  },

  actions: {
    resetFulfilmentState({ commit }) {
      commit('resetFulfilmentState')
    },
    setCustomer({ commit }, value) {
      commit('setCustomer', value)
    },
    setSelectedDeliveryAddress({ commit }, value) {
      commit('setSelectedDeliveryAddress', value)
    },
    setSelectedBillingAddress({ commit }, value) {
      commit('setSelectedBillingAddress', value)
    },
    setSecondaryContactName({ commit }, value) {
      commit('setSecondaryContactName', value)
    },
    setSecondaryContactNumber({ commit }, value) {
      commit('setSecondaryContactNumber', value)
    },
    setBillingAddressSameAsDeliveryAddress({ commit }, value) {
      commit('setBillingAddressSameAsDeliveryAddress', value)
    },
    setVenueOpenAtSetupTime({ commit }, value) {
      commit('setVenueOpenAtSetupTime', value)
    },
    setIsCorporateOrder({ commit }, value) {
      commit('setIsCorporateOrder', value)
    },
    setIsDisposablesRequired({ commit }, value) {
      commit('setIsDisposablesRequired', value)
    },
    setCreateAccount({ commit }, value) {
      commit('setCreateAccount', value)
    },
    setSpecialInstructions({ commit }, value) {
      commit('setSpecialInstructions', value)
    },
    setPromoCode({ commit }, value) {
      commit('setPromoCode', value)
    },
    setCheckoutComplete({ commit }, value) {
      commit('setCheckoutComplete', value)
    },
    setSalesOrder({ commit }, value) {
      commit('setSalesOrder', value)
    },
    setOrderPaid({ commit }, value) {
      commit('setOrderPaid', value)
    }
  },

  getters: {
    getCustomer: state => JSON.parse(JSON.stringify(state.customer)),
    getSelectedDeliveryAddress: state => state.selectedDeliveryAddress,
    getSelectedBillingAddress: state => state.selectedBillingAddress,
    getSecondaryContactName: state => state.secondaryContactName,
    getSecondaryContactNumber: state => state.secondaryContactNumber,
    getBillingAddressSameAsDeliveryAddress: state => state.billingAddressSameAsDeliveryAddress,
    getVenueOpenAtSetupTime: state => state.venueOpenAtSetupTime,
    getIsCorporateOrder: state => state.isCorporateOrder,
    getIsDisposablesRequired: state => state.isDisposablesRequired,
    getCreateAccount: state => state.createAccount,
    getSpecialInstructions: state => state.specialInstructions,
    getPromoCode: state => state.promoCode,
    getCheckoutComplete: state => state.checkoutComplete,
    getSalesOrder: state => state.salesOrder,
    getOrderPaid: state => state.orderPaid
  }
}
