export const analyticsMiddleware = store => {
  store.subscribe(mutation => {
    const { type, payload } = mutation
    // Sample analytics track event
    if (type === 'setSelectedAbout') {
      window.analytics.track('Product List Filtered', {
        filters: payload
      })
    }
  })
}
