import moment from 'moment-timezone'
import { isDisabledDate } from '@/assets/js/order-helper'

function startOfDaysLaterWithDate(date, days = 0) {
  return date
    .startOf('day')
    .add(days, 'days')
    .toDate()
}

function addWorkingDays(finalDate, days = 0) {
  if (days > 0) {
    while (days > 0) {
      days--
      finalDate.add(1, 'day')
      while (
        isDisabledDate(finalDate.toDate()) ||
        ['0', '6'].includes(finalDate.format('e'))
      ) {
        if (finalDate.format('e') === '6') {
          finalDate.add(2, 'days')
        } else {
          finalDate.add(1, 'day')
        }
      }
    }
  } else if (days < 0) {
    while (days < 0) {
      days++
      finalDate.add(-1, 'day')
      while (
        isDisabledDate(finalDate.toDate()) ||
        ['0', '6'].includes(finalDate.format('e'))
      ) {
        if (finalDate.format('e') === '0') {
          finalDate.add(-2, 'days')
        } else {
          finalDate.add(-1, 'day')
        }
      }
    }
  }
  return finalDate
}

export function addTimeUTC(date, hours, minutes = 0) {
  return moment(date)
    .utc()
    .add(hours, 'hours')
    .add(minutes, 'minutes')
    .toDate()
}

export function startOfDaysLaterUTC(days = 0, startDate = null) {
  const currentDate = startDate ? moment(startDate) : moment()
  return startOfDaysLaterWithDate(currentDate.add(currentDate.utcOffset(), 'minutes').utc(), days)
}

export function startOfWorkingDaysLaterUTC(days = 0, startDate = null) {
  const currentDate = startDate ? moment(startDate) : moment()
  let finalDate = currentDate
    .add(currentDate.utcOffset(), 'minutes')
    .utc()
    .startOf('day')
  finalDate = addWorkingDays(finalDate, days)
  return finalDate.toDate()
}

export function startOfTodayUTC() {
  return startOfDaysLaterUTC()
}

export function startOfDateUTC(date) {
  return moment(date)
    .startOf('day')
    .toDate()
}

export function addTime(date, hours, minutes = 0) {
  return moment(date)
    .add(hours, 'hours')
    .add(minutes, 'minutes')
    .toDate()
}

export function startOfDaysLater(days = 0, startDate = null) {
  const currentDate = startDate ? moment(startDate) : moment()
  return startOfDaysLaterWithDate(currentDate, days)
}

export function startOfWorkingDaysLater(days = 0, startDate = null) {
  const currentDate = startDate ? moment(startDate) : moment()
  let finalDate = currentDate.startOf('day')
  finalDate = addWorkingDays(finalDate, days)
  return finalDate.toDate()
}

export function startOfToday() {
  return startOfDaysLater()
}

export function getSameTimeInUTC(date) {
  const currentDate = moment(date)
  return currentDate.add(currentDate.utcOffset(), 'minutes').toDate()
}

export function getSameTimeFromUTC(date) {
  const currentDate = moment(date)
  return currentDate.add(-currentDate.utcOffset(), 'minutes').toDate()
}

// eg. convert 1.30pm in Singapore time (GMT+8) to 1.30pm San Francisco time (GMT-8)
export function getSameTimeInCurrentZone(date) {
  return moment(date)
    .add(480 + new Date().getTimezoneOffset(), 'minutes')
    .toDate()
}

export function getSameTimeFromCurrentZone(date) {
  return moment(date)
    .add(-(480 + new Date().getTimezoneOffset()), 'minutes')
    .toDate()
}

/**
 *
 * @param {Date} startDate
 * @param {Date} stopDate
 */
export function getDatesRange(startDate, stopDate) {
  const ONE_DAY = 24 * 3600 * 1000
  let currentDate = new Date(startDate)
  const days = []
  while (currentDate <= stopDate) {
    days.push(new Date(currentDate))
    currentDate = currentDate - 1 + 1 + ONE_DAY
  }
  return days
}
