export const filters = ['No onion', 'No garlic', 'No leek', 'Separate sauce']

export function formatCost(cost) {
  const formattedCost = parseFloat(cost)
  return formattedCost === Math.floor(cost)
    ? formattedCost.toString()
    : formattedCost.toFixed(2)
}

export function scrollWindowTo(to = 0, duration = 400) {
  const start = window.scrollY
  const change = to - start
  const increment = 20
  let currentTime = 0

  if (duration < increment) {
    duration = increment
  }

  const animateScroll = () => {
    currentTime += increment
    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2
      if (t < 1) return (c / 2) * t * t + b
      t--
      return (-c / 2) * (t * (t - 2) - 1) + b
    }
    const val = easeInOutQuad(currentTime, start, change, duration)
    window.scroll(window.scrollX, val)
    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }
  animateScroll()
}

export function scrollWindowToElement(element, offsetY = 0, duration = 400) {
  scrollWindowTo(window.scrollY + element.getBoundingClientRect().y + offsetY, duration)
}

export function importScript(src) {
  const script = document.createElement('script')
  script.setAttribute('src', src)
  script.async = true
  document.head.appendChild(script)
}

export function isWithinTimeRange(time, startTime, endTime) {
  if (startTime && endTime) {
    return time >= startTime && time <= endTime
  } else if (startTime) {
    return time >= startTime
  } else if (endTime) {
    return time <= endTime
  }
  return true
}
