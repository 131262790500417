export default {
  getCookie(cookieName) {
    const name = cookieName + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const cookies = decodedCookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i]
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1)
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length)
      }
    }
    return ''
  },
  setCookie(cookieName, value, expiry = null) {
    let cookie = `${cookieName}=${value};path=/`
    if (expiry) cookie += `;expires=${expiry}`
    document.cookie = cookie
  },
  deleteCookie(cookieName) {
    document.cookie = `${cookieName}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  }
}
