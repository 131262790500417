export const locations = {
  state: {
    locationConfigs: [],
    specialLocationDeliveryCharge: {}
  },

  mutations: {
    setLocationConfigs(state, value) {
      state.locationConfigs = value
    },
    setSpecialLocationDeliveryCharge(state, value) {
      state.specialLocationDeliveryCharge = value
    }
  },

  actions: {
    setLocationConfigs({ commit }, value) {
      commit('setLocationConfigs', value)
    },
    setSpecialLocationDeliveryCharge({ commit }, value) {
      commit('setSpecialLocationDeliveryCharge', value)
    }
  },

  getters: {
    locationConfigs: state => state.locationConfigs,
    specialLocationDeliveryCharge: state => state.specialLocationDeliveryCharge
  }
}
