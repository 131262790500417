import './polyfill'
import './plugins'
import './mixins'

import * as Sentry from '@sentry/browser'

import App from './App.vue'
import Vue from 'vue'
import i18n from './i18n'
import router from './router'
import store from './store'

if (process.env.VUE_APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: 'https://732aab991c724d9886843dd67e94cc2c@sentry.io/1330812',
    integrations: [new Sentry.Integrations.Vue({ Vue })],
    environment: process.env.VUE_APP_ENVIRONMENT
  })
}

Vue.config.productionTip = false

Vue.directive('focus', {
  // Requires both hooks to account for repeated opening of the form
  inserted: function(el) {
    el.focus()
  },
  update: function(el) {
    el.focus()
  }
})

Vue.prototype.$webSocketUrl = process.env.VUE_APP_WEB_SOCKET_URL

new Vue({
  router,
  store,
  i18n,
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
  render: h => h(App)
}).$mount('#app')

router.afterEach(to => {
  window.analytics.page({ path: to.path, url: window.location.origin + to.path })
})
